import React from "react";
import { Storage } from "aws-amplify";
import Resizer from "react-image-file-resizer";
import { Container, Paper, Typography, Stack, Box } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { UploadWidget } from "../../../components";
import { CompanyWidget } from "../../../../../components";

const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
    );
  });

function UploadLogoStep({ proceed, onAbort, goForward, client }) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [clientKey, setClientKey] = React.useState(Date.now());
  const [progress, setProgress] = React.useState(0);

  const onFileSelect = async (_file) => {
    setProgress(1);
    const image = await resizeImage(_file);
    await Storage.put(`${client.id}.jpg`, image, {
      progressCallback(progress) {
        const progressPercentage = (progress.loaded / progress.total) * 100;
        setProgress(parseInt(progressPercentage));
      },
      errorCallback: (err) => {
        console.error("Unexpected error while uploading", err);
        onAbort({
          generalError: intl.formatMessage({
            description: "General Error: Something Went Wrong",
            defaultMessage:
              "Something went wrong. Please refresh the page and try again or contact support.",
            id: "EqFLp/",
          }),
        });
      },
      contentType: "image/jpeg",
    });
    setClientKey(Date.now());
  };

  const onFileRemove = async () => {
    await Storage.remove(`${client.id}.jpg`);
    setClientKey(Date.now());
  };

  React.useEffect(() => {
    if (proceed === true) {
      goForward();
    }
  }, [proceed, goForward]);

  React.useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 300);
    }

    return () => {
      clearTimeout();
    };
  }, [progress]);

  return (
    <Container sx={{ py: 3, px: isMobile ? 0 : 2 }}>
      <Typography variant="h3" component="div" gutterBottom>
        <FormattedMessage
          description="Onboarding Upload Logo Title"
          defaultMessage="Upload Your Logo"
          id="HrwoLA"
        />
      </Typography>
      <Box component="form" autoComplete="off" noValidate>
        <Paper elevation={isMobile ? 0 : 1} sx={{ p: isMobile ? 0 : 2 }}>
          <Stack spacing={2}>
            <Box>
              <Typography variant="h6" gutterBottom component="div">
                <FormattedMessage
                  description="Onboarding Upload Logo: Upload Section Title"
                  defaultMessage="Upload Your Company Logo"
                  id="wGzxBF"
                />
              </Typography>
              <UploadWidget
                onFileSelect={onFileSelect}
                onFileRemove={onFileRemove}
                progress={progress}
              />
            </Box>
            <Box>
              <Typography variant="h6" gutterBottom component="div">
                <FormattedMessage
                  description="Onboarding Upload Logo: Preview Company Title"
                  defaultMessage="Preview of Your Company"
                  id="mohwaa"
                />
              </Typography>
              <CompanyWidget company={client} key={clientKey} />
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
}

export default UploadLogoStep;

import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import MuiPhoneNumber from "material-ui-phone-number";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useFormik } from "formik";

function ShareViaMSGForm({ channel }) {
  const intl = useIntl();
  const [sentConfirmation, setSentConfirmation] = React.useState(false);
  const activeChannel = channel ? String(channel).toUpperCase() : "SMS";

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setTouched,
    setFieldValue,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      via: activeChannel,
      receiver: "",
    },
    validationSchema: Yup.object({
      via: Yup.string()
        .oneOf(["SMS", "EMAIL", "WHATSAPP"])
        .required(
          intl.formatMessage({
            defaultMessage: "Send via must be one of SMS, Email or WhatsApp",
            description: "Send Poll: Send via is required",
            id: "o0P2is",
          }),
        ),
      receiver: Yup.string()
        .when("via", {
          is: "EMAIL",
          then: (schema) =>
            schema.email(
              intl.formatMessage({
                defaultMessage: "Receiver must be a valid email address",
                description: "Send Poll: Receiver email",
                id: "Jyrz95",
              }),
            ),
          otherwise: (schema) =>
            schema.min(
              10,
              intl.formatMessage({
                defaultMessage:
                  "Receiver phone number must be at least 10 numbers long",
                description: "Send Poll: Receiver min lenght",
                id: "bhmuhP",
              }),
            ),
        })
        .required(
          intl.formatMessage({
            defaultMessage: "Receiver field is required",
            description: "Send Poll: Receiver is required",
            id: "HkpFmd",
          }),
        ),
    }),
    onSubmit: async ({ via, receiver }, { setSubmitting, resetForm }) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      resetForm({ values: { receiver: "", via: activeChannel } });
      console.log(via, receiver);
      setSubmitting(false);
      setSentConfirmation(true);
    },
  });

  const handleSentConfirmationClose = () => {
    setSentConfirmation(false);
  };

  React.useEffect(() => {
    setFieldValue("via", activeChannel, false);
  }, [activeChannel, setFieldValue]);

  return (
    <>
      <Box
        onSubmit={handleSubmit}
        component="form"
        autoComplete="off"
        noValidate
      >
        <Stack spacing={2}>
          <FormControl error={touched.via && Boolean(errors.via)}>
            <FormLabel component="legend" sx={{ mb: 1 }} required>
              <FormattedMessage
                defaultMessage="Send poll via"
                description="Input title: Send via"
                id="JXK5iu"
              />
            </FormLabel>
            <Select
              required
              name="via"
              value={values.via}
              onChange={(event) => {
                handleChange(event);
                setFieldValue("receiver", "");
                setTouched({ ...touched, receiver: false }, false);
              }}
              onBlur={handleBlur}
              onFocus={() => setTouched({ ...touched, via: true }, false)}
              error={touched.via && Boolean(errors.via)}
            >
              <MenuItem value="SMS">
                <FormattedMessage
                  defaultMessage="SMS"
                  description="Select Option: SMS"
                  id="2UfjpY"
                />
              </MenuItem>
              <MenuItem value="EMAIL">
                <FormattedMessage
                  defaultMessage="Email"
                  description="Select Option: Email"
                  id="JjLWEK"
                />
              </MenuItem>
              <MenuItem value="WHATSAPP">
                <FormattedMessage
                  defaultMessage="WhatsApp"
                  description="Select Option: WhatsApp"
                  id="tgTF12"
                />
              </MenuItem>
            </Select>
            {touched.via && Boolean(errors.via) && (
              <FormHelperText>{touched.via && errors.via}</FormHelperText>
            )}
          </FormControl>
          {values.via !== "EMAIL" ? (
            <FormControl>
              <FormLabel component="legend" sx={{ mb: 1 }} required>
                <FormattedMessage
                  defaultMessage="Receiver phone number"
                  description="Input title: Receiver phone"
                  id="EZFjQZ"
                />
              </FormLabel>
              <MuiPhoneNumber
                name="receiver"
                variant="outlined"
                defaultCountry={"bg"}
                onlyCountries={["bg"]}
                value={values.receiver}
                label={intl.formatMessage({
                  defaultMessage: "Phone",
                  description: "Input label: Receiver Phone",
                  id: "2DZI4q",
                })}
                onChange={(val) => setFieldValue("receiver", val)}
                onBlur={handleBlur}
                onFocus={() =>
                  setTouched({ ...touched, receiver: true }, false)
                }
                error={touched.receiver && Boolean(errors.receiver)}
                helperText={touched.receiver && errors.receiver}
              />
            </FormControl>
          ) : (
            <FormControl>
              <FormLabel component="legend" sx={{ mb: 1 }} required>
                <FormattedMessage
                  defaultMessage="Receiver email"
                  description="Input title: Receiver email"
                  id="FNnszy"
                />
              </FormLabel>
              <TextField
                name="receiver"
                variant="outlined"
                label={intl.formatMessage({
                  defaultMessage: "Email",
                  description: "Input label: Receiver Email",
                  id: "8D4mgc",
                })}
                placeholder="someone@email.com"
                value={values.receiver}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.receiver && Boolean(errors.receiver)}
                helperText={touched.receiver && errors.receiver}
              />
            </FormControl>
          )}
          {values.via && values.receiver && Object.keys(errors).length === 0 && (
            <FormGroup>
              <LoadingButton
                loading={isSubmitting}
                variant="outlined"
                type="submit"
              >
                <FormattedMessage
                  defaultMessage="Send"
                  description="Send Action"
                  id="9fxG0s"
                />{" "}
                &rarr;
              </LoadingButton>
              <FormHelperText>
                <Typography variant="overline">
                  <FormattedMessage
                    defaultMessage="<b>Sending Fee:</b> {credits, plural, =0 {free} one {1 credit} other {{credits} credits}}"
                    description="Send Action Fee Disclaimer"
                    id="6Iz1Ef"
                    values={{
                      credits: values.via === "EMAIL" ? 0 : 4,
                      b: (chunks) => <strong>{chunks}</strong>,
                    }}
                  />
                </Typography>
              </FormHelperText>
            </FormGroup>
          )}
        </Stack>
      </Box>
      <Snackbar
        open={sentConfirmation}
        autoHideDuration={3000}
        onClose={handleSentConfirmationClose}
      >
        <Alert
          onClose={handleSentConfirmationClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <FormattedMessage
            defaultMessage="Message successfully sent"
            description="Confirmation: Message successfully sent"
            id="G01xT5"
          />
        </Alert>
      </Snackbar>
    </>
  );
}

export default ShareViaMSGForm;

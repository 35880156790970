import shortlink from "shortlink";
import { API } from "aws-amplify";

import { createLink } from "../graphql/mutations";
import { getLink } from "../graphql/queries";

export async function generateLink(pollID, type) {
  // find unused link
  var linkID, link;
  do {
    linkID = shortlink.generate(8);
    const { data } = await API.graphql({
      query: getLink,
      authMode: "AWS_IAM",
      variables: {
        id: linkID,
      },
    });
    link = data.getLink;
  } while (link);

  // create the link
  await API.graphql({
    query: createLink,
    authMode: "AWS_IAM",
    variables: {
      input: {
        id: linkID,
        type,
        pollID,
      },
    },
  });

  return linkID;
}

import React from "react";
import { MobileStepper, Box, Container, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormattedMessage } from "react-intl";
import { DataStore } from "aws-amplify";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { Client } from "../../../../models";

import {
  IntroStep,
  CreateClientStep,
  AddSocialMediaStep,
  UploadLogoStep,
  AddServicesStep,
  OutroStep,
} from "../components/OnboardingSteps";

const stepsList = [
  IntroStep,
  CreateClientStep,
  AddSocialMediaStep,
  UploadLogoStep,
  AddServicesStep,
  OutroStep,
];

function OnboardingWizard({ client: initClient, onFinish }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [client, setClient] = React.useState(initClient);
  const [proceed, setProceed] = React.useState(false);
  const [skipping, setSkipping] = React.useState(false);
  const [isLastStep, setLastStep] = React.useState(false);
  const [generalError, setGeneralError] = React.useState(null);
  const clientID = client?.id || null;
  const activeStep = client?.oStep || 0;

  const onNext = async () => {
    if (isLastStep) {
      let _client = await DataStore.query(Client, client.id);
      _client = await DataStore.save(
        Client.copyOf(_client, (updated) => {
          updated.oStep = -1;
        }),
      );
      setProceed(true);
      onFinish(_client);
    } else {
      setProceed(true);
    }
  };

  const onAbort = (res) => {
    if (res?.generalError) {
      setGeneralError(res.generalError);
    }
    setProceed(false);
  };

  const onComplete = (res) => {
    setProceed(false);
    if (res?.client) {
      setClient(res.client);
    }
  };

  const goForward = React.useCallback(async () => {
    setSkipping(true);
    let _client = await DataStore.query(Client, clientID);
    _client = await DataStore.save(
      Client.copyOf(_client, (updated) => {
        updated.oStep = _client.oStep + 1;
      }),
    );
    onComplete({ client: _client });
    setSkipping(false);
  }, [clientID]);

  const handleGeneralErrorClose = () => {
    setGeneralError(null);
  };

  React.useEffect(() => {
    if (activeStep === stepsList.length - 1) {
      setLastStep(true);
    }
  }, [activeStep]);

  return (
    <>
      <Container
        maxWidth="md"
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        })}
      >
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          {React.createElement(stepsList[activeStep], {
            client,
            proceed,
            onComplete,
            onAbort,
            goForward,
          })}
        </Box>
        <MobileStepper
          variant="progress"
          steps={stepsList.length}
          activeStep={activeStep}
          position="static"
          sx={{ px: 0 }}
          backButton={
            <LoadingButton
              size="small"
              onClick={goForward}
              disabled={activeStep < 2 || isLastStep}
              loading={skipping}
            >
              {!isMobile && <SkipNextIcon />}
              <FormattedMessage
                id="Y1zJ8P"
                description="Skip Action"
                defaultMessage="Skip"
              />
            </LoadingButton>
          }
          nextButton={
            <LoadingButton size="small" onClick={onNext} loading={proceed}>
              {isLastStep ? (
                <FormattedMessage
                  id="tTGeUK"
                  description="Done Action"
                  defaultMessage="Done"
                />
              ) : (
                <FormattedMessage
                  id="Y3SYjh"
                  description="Next Action"
                  defaultMessage="Next"
                />
              )}
              {!isMobile && <KeyboardArrowRight />}
            </LoadingButton>
          }
        />
      </Container>
      <Snackbar
        open={!!generalError}
        autoHideDuration={15000}
        onClose={handleGeneralErrorClose}
      >
        <Alert
          onClose={handleGeneralErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {generalError}
        </Alert>
      </Snackbar>
    </>
  );
}

export default OnboardingWizard;

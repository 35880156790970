import React from "react";
import {
  Paper,
  Typography,
  Button,
  Stack,
  CircularProgress,
  LinearProgress,
  Box,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CheckIcon from "@mui/icons-material/Check";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

import { formatBytes } from "../../../lib/fileSize";

const Input = styled("input")({
  display: "none",
});

const STATUS_UPLOADED = "uploaded";
const STATUS_REMOVED = "removed";

function UploadWidget({ onFileSelect, onFileRemove, progress }) {
  const [file, setFile] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const uploading = Boolean(progress);
  const hasFile = Boolean(file);

  const fileSelectHandler = async (e) => {
    const _file = e.target.files[0];
    e.target.value = null; // reset the input to initial state for sequential upload
    setFile(_file);
    await onFileSelect(_file);
    setStatus(STATUS_UPLOADED);
  };

  const fileRemoveHandler = async () => {
    setFile(null);
    await onFileRemove();
    setStatus(STATUS_REMOVED);
  };

  React.useEffect(() => {
    if (status) {
      setTimeout(() => {
        setStatus(null);
      }, 3000);
    }

    return () => {
      clearTimeout();
    };
  }, [status]);

  return (
    <>
      <Paper variant="outlined" square sx={{ p: isMobile ? 1 : 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <label htmlFor="upload-logo">
            <Input
              accept="image/*"
              id="upload-logo"
              type="file"
              onChange={fileSelectHandler}
            />
            <Button
              disabled={uploading}
              variant="outlined"
              sx={{
                whiteSpace: "nowrap",
              }}
              endIcon={<UploadFileIcon />}
              component="span"
            >
              <FormattedMessage
                description="Upload Widget: Upload Action"
                defaultMessage="Upload File"
                id="wpTScp"
              />
            </Button>
          </label>
          {uploading && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="overline" component="span">
                <FormattedMessage
                  description="Upload Widget: Uploading"
                  defaultMessage="Uploading"
                  id="J2fyn6"
                />
              </Typography>
              <CircularProgress size={20} />
            </Stack>
          )}
          {!uploading && status === STATUS_UPLOADED && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="overline" component="span">
                <FormattedMessage
                  description="Upload Widget: Done"
                  defaultMessage="Done"
                  id="MmLBQz"
                />
              </Typography>
              <CloudDoneIcon />
            </Stack>
          )}
          {status === STATUS_REMOVED && (
            <Stack direction="row" spacing={1}>
              <Typography variant="overline" component="span">
                <FormattedMessage
                  description="Upload Widget: File Removed"
                  defaultMessage="File Removed"
                  id="TNEwSY"
                />
              </Typography>
              <CheckIcon />
            </Stack>
          )}
        </Stack>
      </Paper>
      {hasFile && (
        <Paper
          variant="outlined"
          square
          sx={{
            p: isMobile ? 1 : 2,
            borderTop: "none",
            backgroundColor: "#FAFAFA",
          }}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
            spacing={isMobile ? 1 : 0}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1}>
              <Box>
                <InsertDriveFileIcon fontSize="large" />
              </Box>
              <Stack justifyContent="space-between">
                <Typography
                  variant="caption"
                  component="span"
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {file.name}
                </Typography>
                <Typography variant="caption" component="span">
                  {formatBytes(file.size)}
                </Typography>
              </Stack>
            </Stack>
            <Button
              color="error"
              size="small"
              variant={isMobile ? "outlined" : "text"}
              fullWidth={isMobile}
              sx={{
                whiteSpace: "nowrap",
              }}
              endIcon={<DeleteOutlineIcon fontSize="inherit" />}
              onClick={fileRemoveHandler}
            >
              <FormattedMessage
                description="Upload Widget: Remove File"
                defaultMessage="Remove File"
                id="RAzbkE"
              />
            </Button>
          </Stack>
        </Paper>
      )}
      {uploading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
      <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
        <Typography variant="caption" component="span">
          <FormattedMessage
            description="Upload Widget: Accepted Files Info"
            defaultMessage="<b>Accepted Files:</b> JPG, PNG, GIF and SVG"
            id="0qxUQq"
            values={{ b: (chunks) => <strong>{chunks}</strong> }}
          />
        </Typography>
      </Stack>
    </>
  );
}

export default UploadWidget;

import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  Button,
} from "@aws-amplify/ui-react";

import Logo from "../assets/logo.svg";

function AmplifyAuthenticator(props) {
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Amplify logo" src={Logo} height="200px" />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={`${tokens.colors.neutral["80"]}`}>
            xtun inc. &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Forgot Your Password?
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();

        return (
          <>
            <Authenticator.SignUp.FormFields />
            <CheckboxField
              errorMessage={validationErrors.acknowledgement}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label="I agree with the Terms & Conditions"
            />
          </>
        );
      },
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
  };

  const services = {
    async validateCustomSignUp(formData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: "You must agree to the Terms & Conditions",
        };
      }
    },
  };

  return (
    <Authenticator
      components={components}
      loginMechanisms={["email"]}
      // socialProviders={["amazon", "apple", "facebook", "google"]} // @TODO
      services={services}
    >
      {() => props.children}
    </Authenticator>
  );
}

export default AmplifyAuthenticator;

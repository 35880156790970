import React from "react";
import { Stack, Rating, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { PollWidgetEnum } from "../../../models";

const val2color = {
  1: "#E62222",
  2: "#FF6752",
  3: "#7D9871",
  4: "#ADC549",
  5: "#61BB47",
};

function StarsWidget({ question, onHover, onVote, value, hover }) {
  const label = hover !== -1 ? hover : value;

  return (
    <Stack spacing={2} alignItems="center">
      <Rating
        readOnly={!!value}
        value={value}
        size="large"
        onChange={(_, _value) => {
          onVote(question.id, _value);
        }}
        onChangeActive={(_, _hover) => {
          onHover(_hover);
        }}
        sx={(theme) => {
          return {
            color: val2color[value || hover],
            fontSize: theme.spacing(5),
          };
        }}
      />
      {label ? (
        <Typography sx={{ height: "1.2rem" }} variant="overline">
          <FormattedMessage
            description="Rating Widget Labels"
            defaultMessage="{widget, select, STARS {{rating, select, 1 {Poor} 2 {Below Average} 3 {Average} 4 {Good} 5 {Excellent} other {Other}}} SENTIMENT {{rating, select, 1 {Dissatisfied} 2 {Satisfied} 3 {Love} other {Other}}} other {Undefined}}"
            values={{
              rating: label,
              widget: PollWidgetEnum.STARS,
            }}
            id="e1S15b"
          />
        </Typography>
      ) : (
        <Typography sx={{ height: "1.2rem" }} variant="caption">
          <FormattedMessage
            description="Voting Widget: Please, choose rating"
            defaultMessage="Please, choose rating"
            id="8gufCH"
          />
        </Typography>
      )}
    </Stack>
  );
}

export default StarsWidget;

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { useIntl } from "react-intl";

import { getLink } from "../../../../graphql/queries";
import { LinkTypesEnum } from "../../../../models";
import { FullscreenLoader, NotFound } from "../../../../components";
import FeedbackModule from "../Feedback/FeedbackModule";
import { generateLink } from "../../../../lib/shortlink";
import { get, set } from "../../../../lib/browserStorage";

function CloudRouterModule() {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [pollID, setPollID] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const linkID = pathname.substring(1);

  useEffect(() => {
    if (!pollID) {
      API.graphql({
        query: getLink,
        authMode: "AWS_IAM",
        variables: {
          id: linkID,
        },
      }).then(async ({ data: { getLink: _link } }) => {
        if (_link) {
          setPollID(_link.pollID);
          if (_link.type === LinkTypesEnum.FAUCET) {
            let link = get(_link.pollID);
            if (link) {
              link = window.atob(link);
            } else {
              link = await generateLink(_link.pollID, LinkTypesEnum.SINGLE);
              const ttl = 12 * 60 * 60 * 1000;
              set(_link.pollID, window.btoa(link), ttl);
            }
            navigate(`/${link}`, { replace: true });
          }
        }
        setLoading(false);
      });
    }

    return () => {
      setLoading(false);
    };
  }, [pollID, linkID, navigate]);

  if (loading) {
    return (
      <FullscreenLoader
        caption={intl.formatMessage({
          description: "Cloud Router navigates you to content loading message",
          defaultMessage: "Navigating you to your content...",
          id: "Z30arm",
        })}
      />
    );
  }

  if (pollID) {
    return <FeedbackModule pollID={pollID} />;
  }

  return <NotFound />;
}

export default CloudRouterModule;

import { Link } from "react-router-dom";
import { Card, CardHeader, CardActions, Button } from "@mui/material";

function BaseActionCard({ title, subheader, actionsContainer, action, icon }) {
  return (
    <Card elevation={2}>
      <CardHeader avatar={icon} title={title} subheader={subheader} />
      {actionsContainer ? (
        actionsContainer
      ) : (
        <CardActions>
          <Button size="small" component={Link} to={action.to}>
            {action.title} &rarr;
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default BaseActionCard;

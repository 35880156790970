import { Container, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";

function Business() {
  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Typography component="div" variant="h4">
        <FormattedMessage
          defaultMessage="Your Business"
          description="Business Title"
          id="Ek5Iye"
        />
      </Typography>
      <Typography component="div" variant="subtitle1" gutterBottom>
        <FormattedMessage
          defaultMessage="Setup and manage your business profile"
          description="Business Subtitle"
          id="Ljta5t"
        />
      </Typography>
    </Container>
  );
}

export default Business;

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ReportTypesEnum = {
  "POLL_RESULTS": "POLL_RESULTS",
  "CLIENT_MONITOR": "CLIENT_MONITOR",
  "BUSINESS_MONITOR": "BUSINESS_MONITOR",
  "ROOT_MONITOR": "ROOT_MONITOR"
};

const PollWidgetEnum = {
  "STARS": "STARS",
  "NPS": "NPS",
  "SENTIMENT": "SENTIMENT"
};

const LinkTypesEnum = {
  "FAUCET": "FAUCET",
  "SINGLE": "SINGLE"
};

const CreditOperationsEnum = {
  "SEED": "SEED",
  "GIFT": "GIFT",
  "TOP_UP": "TOP_UP",
  "AUTO_TOP_UP": "AUTO_TOP_UP",
  "VOTE": "VOTE",
  "MSG_SMS": "MSG_SMS",
  "REVERSAL": "REVERSAL"
};

const { Vote, Client, Service, Question, Poll, Link, CreditHistory, Counter } = initSchema(schema);

export {
  Vote,
  Client,
  Service,
  Question,
  Poll,
  Link,
  CreditHistory,
  Counter,
  ReportTypesEnum,
  PollWidgetEnum,
  LinkTypesEnum,
  CreditOperationsEnum
};
import React from "react";
import { API, DataStore } from "aws-amplify";
import { Box, Stack, Container, Typography, Paper, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { Poll, Question } from "../../../../models";
import { QuestionResultCard } from "../components";
import { getReport } from "../../../../graphql/queries";
import FullscreenLoader from "../../../../components/FullscreenLoader";

function PollResults() {
  const { id } = useParams();
  const intl = useIntl();
  const [loadingPoll, setLoadingPoll] = React.useState(true);
  const [loadingResults, setLoadingResults] = React.useState(true);
  const [poll, setPoll] = React.useState(null);
  const [report, setReport] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);

  React.useEffect(() => {
    DataStore.query(Poll, id).then(async (_poll) => {
      if (_poll) {
        setPoll(_poll);
        const questions = (await DataStore.query(Question)).filter(
          (q) => q.pollID === _poll.id,
        );
        setQuestions(questions);
        setLoadingPoll(false);
      }
    });

    API.graphql({
      query: getReport,
      authMode: "AWS_IAM",
      variables: {
        report: "POLL_RESULTS",
        params: JSON.stringify({ sub: `Poll:${id}` }),
      },
    }).then(({ data: { getReport: _report } }) => {
      setReport(JSON.parse(_report));
      setLoadingResults(false);
    });
  }, [id]);

  if (loadingPoll) {
    return (
      <FullscreenLoader
        caption={intl.formatMessage({
          description: "Poll Results - Loading Poll Details",
          defaultMessage: "Loading poll details...",
          id: "WwvVBW",
        })}
      />
    );
  }

  if (loadingResults) {
    return (
      <FullscreenLoader
        caption={intl.formatMessage({
          description: "Poll Results - Loading Poll Results",
          defaultMessage: "Loading poll results...",
          id: "U4kzHi",
        })}
      />
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <Box>
        <Typography variant="h4" component="div">
          {poll.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage
            defaultMessage="Poll Results"
            description="Poll Results Subtitle"
            id="nWa6BL"
          />
        </Typography>
      </Box>
      {report ? (
        <Stack spacing={4}>
          {questions.map((_q) => (
            <QuestionResultCard
              key={_q.id}
              question={_q}
              results={report[_q.id]}
            />
          ))}
        </Stack>
      ) : (
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Alert severity="warning">
            <FormattedMessage
              defaultMessage="There are no results available for this poll. Please, come back later."
              description="Poll Results - No Results"
              id="9TkCM5"
            />
          </Alert>
        </Paper>
      )}
    </Container>
  );
}

export default PollResults;

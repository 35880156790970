import { Container, Typography, Button } from "@mui/material";

function Homepage() {
  return (
    <Container sx={(theme) => ({ paddingY: theme.spacing(3) })}>
      <Typography variant="h1" sx={{ textAlign: "center", marginY: "3rem" }}>
        xtun.io
      </Typography>
      <Typography variant="h3" sx={{ textAlign: "center", marginY: "3rem" }}>
        Coming Soon
      </Typography>
      <Button variant="outlined" href={process.env.REACT_APP_APP_URL} fullWidth>
        Go to App
      </Button>
    </Container>
  );
}

export default Homepage;

import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { DataStore } from "aws-amplify";
import { Container, Typography, Box, Button } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { DataGrid } from "@mui/x-data-grid";

import { Poll } from "../../../../models";

function Polls() {
  const intl = useIntl();
  const [rows, setRows] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        field: "name",
        headerName: intl.formatMessage({
          defaultMessage: "Name",
          description: "Polls Column: Name",
          id: "nqK7ue",
        }),
        flex: 2,
      },
      {
        field: "createdAt",
        headerName: intl.formatMessage({
          defaultMessage: "Date",
          description: "Polls Column: Created At",
          id: "ImWen9",
        }),
        type: "dateTime",
        flex: 1,
        renderCell: ({ value }) => {
          return <Moment fromNow>{value}</Moment>;
        },
      },
      {
        field: "actions",
        headerName: intl.formatMessage({
          defaultMessage: "Actions",
          description: "Polls Column: Actions",
          id: "Z17H0a",
        }),
        type: "actions",
        getActions: ({ id }) => [
          <Button component={Link} to={`/poll/${id}`}>
            <FormattedMessage
              defaultMessage="View"
              description="View Action"
              id="Z0lZ8m"
            />
          </Button>,
        ],
      },
    ],
    [intl],
  );

  React.useEffect(() => {
    DataStore.query(Poll).then((_rows) => setRows(_rows));
  }, []);

  return (
    <Container
      maxWidth="xl"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        py: theme.spacing(2),
        height: `calc(100% - ${theme.spacing(8)})`,
      })}
    >
      <Typography variant="h3">
        <FormattedMessage
          defaultMessage="Your Polls"
          description="Your Polls Title"
          id="wYb1ah"
        />
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage
          defaultMessage="List of all polls you've created"
          description="Your Polls Subtitle"
          id="yCBqLD"
        />
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          rows={rows}
          columns={columns}
        />
      </Box>
    </Container>
  );
}

export default Polls;

import { Container, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

function HelpCenter() {
  return (
    <Container maxWidth="xl" sx={{ my: 2 }}>
      <Typography variant="overline" tag="p" gutterBottom>
        <FormattedMessage
          defaultMessage="Coming Soon"
          description="Coming Soon"
          id="+aycNd"
        />
      </Typography>
      <Typography variant="h4" tag="div">
        <FormattedMessage
          defaultMessage="Help Center"
          description="Help Center Title"
          id="rHAmn6"
        />
      </Typography>
      <Typography variant="body1" tag="div">
        <FormattedMessage
          defaultMessage="If you need immediate help please write directly to <link>{email}</link>"
          description="Help Center Coming Soon MSG"
          id="ijnzMc"
          values={{
            link: (chunks) => <a href="mailto:office@xtun.io">{chunks}</a>,
            email: "office@xtun.io",
          }}
        />
      </Typography>
    </Container>
  );
}

export default HelpCenter;

import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  Card,
  CardHeader,
  CardActions,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const data = [
  {
    date: "21/02",
    score: 59,
  },
  {
    date: "22/02",
    score: 63,
  },
  {
    date: "23/02",
    score: 64,
  },
  {
    date: "24/02",
    score: 61,
  },
  {
    date: "25/02",
    score: 58,
  },
  {
    date: "26/02",
    score: 64,
  },
  {
    date: "27/02",
    score: 69,
  },
];

function TrendWidget() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card elevation={1}>
      <CardHeader
        title="Trend"
        subheader="Your Xtun score for the past 7 days."
        action={
          <IconButton aria-label="help" size="large">
            <HelpIcon />
          </IconButton>
        }
      />
      <ResponsiveContainer aspect={1.17}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="1 3" />
          <XAxis dataKey="date" />
          <Tooltip />
          <Line type="monotone" dataKey="score" stroke="#000000" />
        </LineChart>
      </ResponsiveContainer>
      <CardActions>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Last 7 days
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
          <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
          <MenuItem onClick={handleClose}>Year to Date</MenuItem>
        </Menu>
      </CardActions>
    </Card>
  );
}

export default TrendWidget;

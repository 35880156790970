import { BrowserRouter, Routes, Route } from "react-router-dom";

import CloudRouterModule from "./Service/CloudRouter/CloudRouterModule";
import { Homepage } from "./screens";

function WebModule() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<CloudRouterModule />} />
      </Routes>
    </BrowserRouter>
  );
}

export default WebModule;

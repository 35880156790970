import React from "react";
import {
  Stack,
  Typography,
  Card,
  CardHeader,
  Chip,
  CardContent,
} from "@mui/material";
import {
  ComposedChart,
  Line,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { FormattedMessage, useIntl } from "react-intl";
import { val2vote } from "../../../../components/QuestionWidget/widgets/SentimentWidget";
import { useTheme } from "@mui/material/styles";

function QuestionResultCard({ question, results }) {
  const theme = useTheme();
  const intl = useIntl();
  const [activeDataSet, setActiveDataSet] = React.useState([]);

  React.useEffect(() => {
    setActiveDataSet(results[results.length - 1]);
  }, [results]);

  return (
    <Card elevation={2}>
      <CardHeader
        title={question.question}
        subheader={intl.formatMessage(
          {
            defaultMessage:
              "{widget, select, STARS {Measures Rating} SENTIMENT {Measures Sentiment} other {Other}}",
            description: "Rating widget description",
            id: "8qXOjt",
          },
          { widget: question.widget },
        )}
      />
      <ResponsiveContainer height={300}>
        <ComposedChart data={results}>
          <CartesianGrid strokeDasharray="1 3" />
          <YAxis yAxisId="left" dataKey="score" ticks={[1, 2, 3, 4, 5]} />
          <YAxis yAxisId="right" dataKey="imp" orientation="right" />
          <XAxis
            dataKey="date"
            tickFormatter={(val) => {
              return intl.formatDate(val);
            }}
          />
          <Bar
            yAxisId="right"
            dataKey="imp"
            barSize={20}
            fill={theme.palette.secondary.main}
            onMouseOver={({ payload }) => setActiveDataSet(payload)}
            onClick={({ payload }) => setActiveDataSet(payload)}
          />
          <Bar
            yAxisId="right"
            dataKey="vote"
            barSize={20}
            fill={theme.palette.primary.main}
            onMouseOver={({ payload }) => setActiveDataSet(payload)}
            onClick={({ payload }) => setActiveDataSet(payload)}
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="score"
            stroke={theme.palette.primary.dark}
            connectNulls={true}
            strokeWidth={1}
            dot={{
              onClick: ({ payload }) => setActiveDataSet(payload),
              onMouseOver: ({ payload }) => setActiveDataSet(payload),
            }}
          />
          <ReferenceLine
            yAxisId="left"
            x={activeDataSet.date}
            stroke="#bdbdbd"
            strokeDasharray="3 3"
          />
          <ReferenceLine
            yAxisId="left"
            y={activeDataSet.score}
            stroke="#bdbdbd"
            strokeDasharray="3 3"
          />
          <Legend
            verticalAlign="top"
            height={40}
            formatter={(val) =>
              intl.formatMessage(
                {
                  defaultMessage:
                    "{val, select, imp {Impressions} vote {Votes} score {Score} other {other}}",
                  id: "TqiGjy",
                  description: "Question Data Set Field Labels",
                },
                { val },
              )
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
      <CardContent>
        <Typography variant="h6" component="div">
          <FormattedMessage
            defaultMessage="{periodStart, date, long}"
            id="wkUAFo"
            description="Question Result Card Stats Section Title"
            values={{
              periodStart: activeDataSet.date
                ? new Date(activeDataSet.date)
                : new Date(),
            }}
          />
        </Typography>
        <Stack>
          {Boolean(activeDataSet.rating) && (
            <Stack direction="row" spacing={1} alignItems="center">
              <FormattedMessage
                defaultMessage="<label>Most Common Answer:</label><chip>{answer}</chip>"
                description="Question Results: Most Common Answer"
                id="EexNAU"
                values={{
                  label: (chunks) => (
                    <Typography variant="overline" component="span">
                      {chunks}
                    </Typography>
                  ),
                  chip: (chunks) => (
                    <Chip
                      label={chunks}
                      size="small"
                      color={
                        activeDataSet.rating <= 2
                          ? "error"
                          : activeDataSet.rating > 2 &&
                            activeDataSet.rating <= 4
                          ? "warning"
                          : "success"
                      }
                      variant="outlined"
                      sx={{ textTransform: "uppercase" }}
                    />
                  ),
                  answer: (
                    <FormattedMessage
                      description="Rating Widget Labels"
                      defaultMessage="{widget, select, STARS {{rating, select, 1 {Poor} 2 {Below Average} 3 {Average} 4 {Good} 5 {Excellent} other {Other}}} SENTIMENT {{rating, select, 1 {Dissatisfied} 2 {Satisfied} 3 {Love} other {Other}}} other {Undefined}}"
                      values={{
                        rating:
                          question.widget === "SENTIMENT"
                            ? val2vote[activeDataSet.rating]
                            : activeDataSet.rating,
                        widget: question.widget,
                      }}
                      id="e1S15b"
                    />
                  ),
                }}
              />
            </Stack>
          )}
          {Boolean(activeDataSet.score) && (
            <Stack direction="row" spacing={1} alignItems="center">
              <FormattedMessage
                defaultMessage="<label>AVG SCORE:</label> <counter>{score}</counter><caption>of 5</caption>"
                description="Question Results: Most Common Answer"
                id="4h1dpv"
                values={{
                  label: (chunks) => (
                    <Typography variant="overline" component="span">
                      {chunks}
                    </Typography>
                  ),
                  counter: (chunks) => (
                    <Typography variant="button" component="span">
                      {chunks}
                    </Typography>
                  ),
                  caption: (chunks) => (
                    <Typography variant="caption" component="span">
                      {chunks}
                    </Typography>
                  ),
                  score: activeDataSet.score,
                }}
              />
            </Stack>
          )}
          {activeDataSet.imp && (
            <Stack direction="row" spacing={1} alignItems="center">
              <FormattedMessage
                defaultMessage="<label>IMPRESSIONS:</label> <counter>{impressions}</counter>"
                description="Question Results: Most Common Answer"
                id="66pRxF"
                values={{
                  label: (chunks) => (
                    <Typography variant="overline" component="span">
                      {chunks}
                    </Typography>
                  ),
                  counter: (chunks) => (
                    <Typography variant="button" component="span">
                      {chunks}
                    </Typography>
                  ),
                  impressions: activeDataSet.imp,
                }}
              />
            </Stack>
          )}
          {Boolean(activeDataSet.vote) && (
            <Stack direction="row" spacing={1} alignItems="center">
              <FormattedMessage
                defaultMessage="<label>VOTES:</label> <counter>{votes}</counter>"
                description="Question Results: Most Common Answer"
                id="Yt9VaJ"
                values={{
                  label: (chunks) => (
                    <Typography variant="overline" component="span">
                      {chunks}
                    </Typography>
                  ),
                  counter: (chunks) => (
                    <Typography variant="button" component="span">
                      {chunks}
                    </Typography>
                  ),
                  caption: (chunks) => (
                    <Typography variant="caption" component="span">
                      {chunks}
                    </Typography>
                  ),
                  votes: activeDataSet.vote,
                }}
              />
            </Stack>
          )}

          {Boolean(activeDataSet.bounceRate) && (
            <Stack direction="row" spacing={1} alignItems="center">
              <FormattedMessage
                defaultMessage="<label>Bounce Rate:</label><counter>{score}%</counter><chip>{status, select, BAD {Bad} NORMAL {Normal} GOOD {Good} EXCELLENT {Excellent} other {OTHER}}</chip>"
                description="Question Results: Bounce Rate"
                id="B1PFVN"
                values={{
                  label: (chunks) => (
                    <Typography variant="overline" component="span">
                      {chunks}
                    </Typography>
                  ),
                  counter: (chunks) => (
                    <Typography variant="button" component="span">
                      {chunks}
                    </Typography>
                  ),
                  chip: (chunks) => (
                    <Chip
                      label={chunks}
                      size="small"
                      color={
                        activeDataSet.bounceRate.status === "BAD"
                          ? "error"
                          : activeDataSet.bounceRate.status === "NORMAL"
                          ? "warning"
                          : "success"
                      }
                      variant="outlined"
                      sx={{ textTransform: "uppercase" }}
                    />
                  ),
                  score: activeDataSet.bounceRate.score,
                  status: activeDataSet.bounceRate.status,
                }}
              />
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default QuestionResultCard;

import { useIntl, FormattedMessage } from "react-intl";
import { CardActions, Button, Link } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";

import BaseActionCard from "./BaseActionCard";

function DownloadInvoicesActionCard() {
  const intl = useIntl();

  return (
    <BaseActionCard
      title={intl.formatMessage({
        defaultMessage: "Need an Invoice?",
        description: "Download Invoices Card Title",
        id: "dSx40j",
      })}
      subheader={intl.formatMessage(
        {
          defaultMessage:
            "We are currently developing this functionality. For the time being, please contact support by <link>email</link> in order to receive your invoices. In the future you are going to be able to view and download all invoices from here.",
          description: "Download Invoices Card Subheader",
          id: "xdHNGb",
        },
        {
          link: (chunks) => (
            <Link href="mailto:office@xtun.io" target="_blank" rel="noreferrer">
              {chunks}
            </Link>
          ),
        },
      )}
      actionsContainer={
        <CardActions>
          <Button size="small" disabled={true}>
            <FormattedMessage
              defaultMessage="Download Invoices"
              description="Download Invoices Action"
              id="QVen1n"
            />{" "}
            &rarr;
          </Button>
        </CardActions>
      }
      // @TODO
      // subheader={intl.formatMessage({
      //   defaultMessage:
      //     "Don't worry, we got you covered. You can see and download all your past and present invoices.",
      //   description: "Download Invoices Card Subheader",
      //   id: "DKVisU",
      // })}
      // action={{
      //   to: "/invoices",
      //   title: intl.formatMessage({
      //     defaultMessage: "Download Invoices",
      //     description: "Download Invoices Action",
      //     id: "QVen1n",
      //   }),
      // }}
      icon={<ReceiptIcon fontSize="large" />}
    />
  );
}

export default DownloadInvoicesActionCard;

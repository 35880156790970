import React from "react";
import {
  Paper,
  Typography,
  Divider,
  Stack,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import Moment from "react-moment";
import { Storage } from "aws-amplify";
import Avatar from "react-avatar";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WebIcon from "@mui/icons-material/Web";

function CompanyWidget({ company }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = React.useState(true);
  const [logo, setLogo] = React.useState(null);
  const ratings_count = 0; // @TODO
  const hasSocials =
    Boolean(company.fb) ||
    Boolean(company.ig) ||
    Boolean(company.tw) ||
    Boolean(company.site);

  const handleLoaded = () => {
    setLoading(false);
  };

  const handleMissingLogo = () => {
    setLogo(null);
    setLoading(false);
  };

  React.useEffect(() => {
    Storage.get(`${company.id}.jpg`).then((logo) => {
      setLogo(logo);
    });
  }, [company]);

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Stack direction={isMobile ? "column" : "row"} spacing={2}>
        <Box width={isMobile ? null : 120} sx={{ alignSelf: "center" }}>
          {logo && (
            <img
              src={logo}
              alt={`${company.name}'s Logo`}
              width={isMobile ? null : 120}
              onLoad={handleLoaded}
              onError={handleMissingLogo}
              style={{ display: "block", maxWidth: "100%", maxHeight: 160 }}
            />
          )}
          {!loading && !logo && <Avatar name={company.name} size={120} />}
        </Box>
        <Stack justifyContent="space-between" spacing={2}>
          <Stack direction="column">
            <Typography variant="h5" gutterBottom>
              {company.name}
            </Typography>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={isMobile ? null : 1}
            >
              <Typography variant="caption">
                <FormattedMessage
                  description="Company Registration Date"
                  defaultMessage="Registered {when}"
                  values={{
                    when: <Moment fromNow>{company.createdAt}</Moment>,
                  }}
                  id="ZiptPE"
                />
              </Typography>
              {ratings_count > 0 && (
                <>
                  {!isMobile && (
                    <Divider orientation="vertical" variant="middle" flexItem />
                  )}
                  <Typography variant="caption">
                    <FormattedMessage
                      id="J6zdCb"
                      description="Count Received Ratings for a Company"
                      defaultMessage="Received {ratings_count, plural, one {# rating} other {# ratings}}"
                      values={{ ratings_count }}
                    />
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
          {isMobile && hasSocials && <Divider />}
          <Stack direction={isMobile ? "column" : "row"} spacing={1}>
            <Stack direction="row" spacing={1} justifyContent="space-evenly">
              {company.fb && (
                <IconButton
                  href={`https://facebook.com/${company.fb}`}
                  target="_blank"
                  aria-label="Visit Company Facebook Page"
                  color="secondary"
                  size="large">
                  <FacebookIcon />
                </IconButton>
              )}
              {company.ig && (
                <IconButton
                  href={`https://instagram.com/${company.ig}`}
                  target="_blank"
                  aria-label="Visit Company Instagram Page"
                  color="secondary"
                  size="large">
                  <InstagramIcon />
                </IconButton>
              )}
              {company.tw && (
                <IconButton
                  href={`https://twitter.com/${company.tw}`}
                  target="_blank"
                  aria-label="Visit Company Twitter Page"
                  color="secondary"
                  size="large">
                  <TwitterIcon />
                </IconButton>
              )}
            </Stack>
            {company.site && (
              <Button
                href={company.site}
                target="_blank"
                startIcon={<WebIcon />}
                variant={isMobile ? "outlined" : "text"}
              >
                {company.site.replace("https://", "").replace("http://", "")}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default CompanyWidget;

import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { DataStore } from "aws-amplify";

import {
  SetupBusinessActionCard,
  AddSocialMediaActionCard,
  CreatePollActionCard,
  UploadLogoActionCard,
  TopUpReminderCard,
  DownloadInvoicesActionCard,
  LatestPollsListingWidget,
  // HelpCenterWidget,
} from "../components";
import { ClientContext } from "../../../../context";
import { Poll } from "../../../../models";

const TOD_PERIODS_ENUM = {
  MORNING: "morning",
  AFTERNOON: "afternoon",
  EVENING: "evening",
  NIGHT: "night",
};
const HOUR_TO_TOD = {
  0: TOD_PERIODS_ENUM.EVENING,
  1: TOD_PERIODS_ENUM.EVENING,
  2: TOD_PERIODS_ENUM.NIGHT,
  3: TOD_PERIODS_ENUM.NIGHT,
  4: TOD_PERIODS_ENUM.NIGHT,
  5: TOD_PERIODS_ENUM.NIGHT,
  6: TOD_PERIODS_ENUM.NIGHT,
  7: TOD_PERIODS_ENUM.MORNING,
  8: TOD_PERIODS_ENUM.MORNING,
  9: TOD_PERIODS_ENUM.MORNING,
  10: TOD_PERIODS_ENUM.MORNING,
  11: TOD_PERIODS_ENUM.AFTERNOON,
  12: TOD_PERIODS_ENUM.AFTERNOON,
  13: TOD_PERIODS_ENUM.AFTERNOON,
  14: TOD_PERIODS_ENUM.AFTERNOON,
  15: TOD_PERIODS_ENUM.AFTERNOON,
  16: TOD_PERIODS_ENUM.AFTERNOON,
  17: TOD_PERIODS_ENUM.AFTERNOON,
  18: TOD_PERIODS_ENUM.AFTERNOON,
  19: TOD_PERIODS_ENUM.EVENING,
  20: TOD_PERIODS_ENUM.EVENING,
  21: TOD_PERIODS_ENUM.EVENING,
  22: TOD_PERIODS_ENUM.EVENING,
  23: TOD_PERIODS_ENUM.EVENING,
};

function Dashboard() {
  const { active: client } = React.useContext(ClientContext);
  const [hasPolls, setHasPolls] = React.useState(false);

  const cardsList = [
    { Widget: TopUpReminderCard, shouldRender: () => client.credits < 100 },
    { Widget: CreatePollActionCard, shouldRender: () => true },
    { Widget: LatestPollsListingWidget, shouldRender: () => hasPolls },
    { Widget: DownloadInvoicesActionCard, shouldRender: () => true },
    { Widget: UploadLogoActionCard, shouldRender: () => true },
    { Widget: SetupBusinessActionCard, shouldRender: () => true },
    { Widget: AddSocialMediaActionCard, shouldRender: () => true },
  ];

  React.useEffect(() => {
    DataStore.query(Poll).then((_polls) => {
      setHasPolls(Boolean(_polls.length));
    });
  }, []);

  return (
    <Container maxWidth="xl" sx={{ my: 2 }}>
      <Typography component="div" variant="h4">
        <FormattedMessage
          defaultMessage="{tod, select, morning {Good Morning {client}} afternoon {Good Afternoon {client}} evening {Good Evening {client}} night {It's nice to see you {client}} other {Hi {client}}}"
          description="Assistant Title"
          id="5gPfGk"
          values={{
            client: client.name,
            tod: HOUR_TO_TOD[new Date().getHours()],
          }}
        />
      </Typography>
      <Typography component="div" variant="subtitle1">
        <FormattedMessage
          defaultMessage="Welcome back to Xtun"
          description="Assistant subtitle"
          id="wiHF9C"
        />
      </Typography>
      <Box
        component="ul"
        sx={(theme) => ({
          mt: 3,
          listStyle: "none",
          p: 0,
          [theme.breakpoints.up("xs")]: {
            columnCount: 1,
          },
          [theme.breakpoints.up("sm")]: {
            columnCount: 2,
          },
          [theme.breakpoints.up("xl")]: {
            columnCount: 3,
          },
        })}
      >
        {cardsList.map(({ Widget, shouldRender }, key) => {
          if (!shouldRender()) {
            return null;
          }

          return (
            <Box
              component="li"
              sx={{
                mb: 3,
                "&:last-child": { mb: 0 },
                display: "inline-block",
                width: "100%",
              }}
              key={key}
            >
              <Widget />
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

export default Dashboard;

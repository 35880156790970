import React, { useContext } from "react";
import { useIntl } from "react-intl";

import { ClientContext } from "../../context";
import { FullscreenLoader } from "../../components";
import AppModule from "./App/AppModule";
import OnboardingModule from "./Onboarding/OnboardingModule";

function AuthModule() {
  const intl = useIntl();
  const { loading: loadingClient, active: activeClient } =
    useContext(ClientContext);
  const isOnboardingDone = !!activeClient;

  if (loadingClient) {
    return (
      <FullscreenLoader
        caption={intl.formatMessage({
          description: "Loading account data screen",
          defaultMessage: "Loading your account data...",
          id: "AusJdh",
        })}
      />
    );
  }

  return isOnboardingDone ? <AppModule /> : <OnboardingModule />;
}

export default AuthModule;

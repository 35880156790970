import { useContext, useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Typography,
  TextField,
  FormGroup,
  Paper,
  FormHelperText,
  Stack,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import shortlink from "shortlink";
import { useIntl, FormattedMessage } from "react-intl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import DeleteIcon from "@mui/icons-material/Delete";

import { generateLink } from "../../../../lib/shortlink";
import { Poll, Question, LinkTypesEnum } from "../../../../models";
import { ClientContext } from "../../../../context";
import { QuestionWidget } from "../../../../components";
import { PollWidgetRadioGroup } from "../components";
import PollBusinessAspectSelector from "../components/PollBusinessAspectSelector";

function CreatePoll() {
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const { active: activeClient } = useContext(ClientContext);
  const [loadingPoll, setLoadingPoll] = useState(false);
  const [activeQ, setActiveQ] = useState(0);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const isEditMode = Boolean(id);

  const defaultQuestion = {
    id: null,
    service: null,
    widget: null,
    question: "",
  };

  const formik = useFormik({
    initialTouched: {
      questions: [
        { id: false, service: false, widget: false, question: false },
      ],
      name: false,
    },
    initialValues: {
      questions: [defaultQuestion],
      name: intl.formatMessage(
        {
          description: "Poll Default Name",
          defaultMessage: "Poll #{random}",
          id: "iI5prD",
        },
        { random: shortlink.generate(3).toUpperCase() },
      ),
    },
    validationSchema: Yup.object({
      questions: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          service: Yup.object()
            .shape({ name: Yup.string(), id: Yup.string().nullable(true) })
            .required(
              intl.formatMessage({
                description: "Create Poll Question: Service is required.",
                defaultMessage:
                  "Please, select or add the service for which this question is going to be related",
                id: "S5NWTv",
              }),
            )
            .nullable(),
          widget: Yup.string()
            .oneOf(
              ["STARS", "SENTIMENT", "NPS"],
              "Widget must be one of STARS, SENTIMENT or NPS.",
            )
            .required(
              intl.formatMessage({
                description: "Create Poll Question: Widget is required.",
                defaultMessage:
                  "Please, select the widget to be used for this question",
                id: "cT113T",
              }),
            ),
          question: Yup.string().required(
            intl.formatMessage({
              description: "Create Poll Question: Question is required.",
              defaultMessage: "Question is required field",
              id: "zVIa2j",
            }),
          ),
        }),
      ),
      name: Yup.string().required(
        intl.formatMessage({
          description: "Create Poll: Name is required.",
          defaultMessage: "Poll name is required field",
          id: "zZ3a4a",
        }),
      ),
    }),
    onSubmit: async ({ questions, name }, { setSubmitting }) => {
      const _poll = isEditMode
        ? Poll.copyOf(await DataStore.query(Poll, id), (updated) => {
            updated.name = name;
          })
        : new Poll({
            name,
            clientID: activeClient.id,
          });
      const poll = await DataStore.save(_poll);

      await Promise.all(
        questions.map(async ({ id, service, widget, question }) => {
          const isNewQuestion = !Boolean(id);
          if (!Boolean(service.createdAt)) {
            await DataStore.save(service);
          }
          const _question = isNewQuestion
            ? new Question({
                question,
                widget,
                serviceID: service.id,
                pollID: poll.id,
              })
            : Question.copyOf(
                await DataStore.query(Question, id),
                (updated) => {
                  updated.question = question;
                  updated.widget = widget;
                  updated.serviceID = service.id;
                },
              );

          await DataStore.save(_question);
        }),
      );

      if (deletedQuestions.length > 0) {
        await Promise.all(
          deletedQuestions.map(async (delQ) => {
            const _q = await DataStore.query(Question, delQ);
            if (_q) await DataStore.delete(_q);
          }),
        );
      }

      if (!isEditMode) {
        await generateLink(poll.id, LinkTypesEnum.FAUCET);
        // XXX Sets artificial waiting so the link is synced to datastore
        await new Promise((resolve) => setTimeout(resolve, 300));
        navigate(`/poll/${poll.id}/share`);
      }

      setSubmitting(false);
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    touched,
    errors,
    isSubmitting,
    dirty,
    isValid,
  } = formik;

  const hasQuestionError = (field, index) =>
    touched.questions?.[index]?.[field] &&
    Boolean(errors.questions?.[index]?.[field]);

  const getQuestionError = (field, index) =>
    touched.questions?.[index]?.[field] && errors.questions?.[index]?.[field];

  // auto generate a question based on the service and widget
  const { questions: questionValues } = values;
  const { questions: touchedQuestions } = touched;
  useEffect(() => {
    if (!questionValues[activeQ]?.id) {
      if (
        questionValues[activeQ]?.service &&
        questionValues[activeQ]?.widget &&
        !touchedQuestions?.[activeQ]?.question
      ) {
        const widget2Question = {
          STARS: intl.formatMessage({
            description: "Poll rating widget default question template",
            defaultMessage: "How do you rate [service]?",
            id: "FG9dOp",
          }),
          SENTIMENT: intl.formatMessage({
            description: "Poll sentiment widget default question template",
            defaultMessage: "How do you feel about [service]?",
            id: "UdvjQN",
          }),
          NPS: intl.formatMessage({
            description: "Poll nps widget default question template",
            defaultMessage: `How likely are you to recommend [service] on a scale from 1 to 10?`,
            id: "qWIAtq",
          }),
        };
        const question = widget2Question[
          questionValues[activeQ].widget
        ].replace(
          "[service]",
          questionValues[activeQ].service.name.charAt(0).toLowerCase() +
            questionValues[activeQ].service.name.slice(1),
        );
        setFieldValue(`questions.${activeQ}.question`, question);
      }
    }
  }, [intl, questionValues, activeQ, touchedQuestions, setFieldValue]);

  useEffect(() => {
    if (id) {
      setActiveQ(-1);
      setLoadingPoll(true);
      DataStore.query(Poll, id).then(async (_poll) => {
        if (_poll) {
          setFieldValue(`name`, _poll.name);
          const questions = (await DataStore.query(Question)).filter(
            (q) => q.pollID === _poll.id,
          );
          questions.forEach((_q, i) => {
            setFieldValue(`questions.${i}.id`, _q.id);
            setFieldValue(`questions.${i}.question`, _q.question);
            setFieldValue(`questions.${i}.service`, _q.Service);
            setFieldValue(`questions.${i}.widget`, _q.widget);
          });
          setLoadingPoll(false);
        }
      });
    }
  }, [id, setFieldValue]);

  if (isEditMode && loadingPoll) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Container maxWidth="lg" sx={{ py: 2 }}>
        {isEditMode ? (
          <Typography variant="h4" component="div">
            <FormattedMessage
              defaultMessage="Edit Poll"
              description="Edit Poll Title"
              id="YfiEKP"
            />
          </Typography>
        ) : (
          <Typography variant="h4" component="div">
            <FormattedMessage
              defaultMessage="Create Poll"
              description="Create Poll Title"
              id="dCRq57"
            />
          </Typography>
        )}
        <Typography variant="subtitle1" gutterBottom>
          <FormattedMessage
            defaultMessage="Tell us a little bit about your poll"
            description="Create Poll Subtitle"
            id="X4Pl1y"
          />
        </Typography>
        <Box
          onSubmit={handleSubmit}
          component="form"
          autoComplete="off"
          noValidate
        >
          <FieldArray name="questions">
            {({ remove, push }) => (
              <ClickAwayListener onClickAway={() => setActiveQ(-1)}>
                <div>
                  {values.questions.map((_question, index) => {
                    const isFilled =
                      values.questions[index].service &&
                      values.questions[index].widget &&
                      values.questions[index].question;
                    const isActive = activeQ === index;

                    return (
                      <Paper
                        elevation={isActive ? 2 : undefined}
                        variant={isActive ? "elevation" : "outlined"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                          p: 2,
                          cursor: !isActive && isFilled ? "pointer" : "default",
                        }}
                        key={index}
                        onClick={() => setActiveQ(index)}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            maxWidth: "600px",
                            display: isFilled && !isActive ? "none" : undefined,
                          }}
                          spacing={2}
                        >
                          {/* Business Aspect Selection */}
                          <FormGroup>
                            <FormLabel component="legend" required>
                              <FormattedMessage
                                defaultMessage="Which service to rate"
                                description="Input title: Poll Service"
                                id="hLybUS"
                              />
                            </FormLabel>
                            <FormControl
                              error={hasQuestionError("service", index)}
                            >
                              <PollBusinessAspectSelector
                                value={values.questions[index].service}
                                name={`questions.${index}.service`}
                                onChange={(val) =>
                                  setFieldValue(
                                    `questions.${index}.service`,
                                    val,
                                  )
                                }
                                onBlur={handleBlur}
                                error={hasQuestionError("service", index)}
                              />
                              {hasQuestionError("service", index) && (
                                <FormHelperText>
                                  {getQuestionError("service", index)}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </FormGroup>
                          {/* Widget Selection */}
                          <FormControl
                            component="fieldset"
                            error={hasQuestionError("widget", index)}
                            onBlur={handleBlur}
                          >
                            <FormLabel component="legend" required>
                              <FormattedMessage
                                defaultMessage="Which widget to use"
                                description="Input title: Poll Widget"
                                id="xcwDla"
                              />
                            </FormLabel>
                            <PollWidgetRadioGroup
                              name={`questions.${index}.widget`}
                              widget={values.questions[index].widget}
                              onChange={handleChange}
                            />
                            {hasQuestionError("widget", index) && (
                              <FormHelperText>
                                {getQuestionError("widget", index)}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {/* Poll Question */}
                          <FormGroup>
                            <FormLabel component="legend" required>
                              <FormattedMessage
                                defaultMessage="What do you want to known"
                                description="Input title: Poll Question"
                                id="Vhy/KG"
                              />
                            </FormLabel>
                            <TextField
                              id={`questions.${index}.question`}
                              label={intl.formatMessage({
                                description: "Input label: Poll Question",
                                defaultMessage: "Question",
                                id: "HzWzmK",
                              })}
                              placeholder={intl.formatMessage({
                                description: "Input placeholder: Poll Question",
                                defaultMessage: "Enter poll question",
                                id: "CLHNC8",
                              })}
                              multiline
                              variant="outlined"
                              margin="dense"
                              value={values.questions[index].question}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={() => {
                                touched.questions[index].question = true;
                              }} // helps prevent auto generation from being triggered after user input
                              error={hasQuestionError("question", index)}
                              helperText={getQuestionError("question", index)}
                            />
                          </FormGroup>
                        </Stack>
                        {isActive && values.questions.length > 1 && (
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                            sx={{ mt: 2 }}
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={async () => {
                                const questionID = values.questions[index].id;
                                if (questionID) {
                                  setDeletedQuestions((currQs) => {
                                    const newQs = [...currQs];
                                    newQs.push(questionID);
                                    return newQs;
                                  });
                                }
                                await remove(index);
                                const nextActive = index - 1;
                                setActiveQ(nextActive > 0 ? nextActive : 0);
                              }}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )}
                        {!isActive && isFilled && (
                          <QuestionWidget
                            sx={{ width: "100%", maxWidth: "600px" }}
                            question={{
                              question: values.questions[index].question,
                              Service: values.questions[index].service,
                              widget: values.questions[index].widget,
                            }}
                          />
                        )}
                      </Paper>
                    );
                  })}
                  <LoadingButton
                    disabled={isSubmitting}
                    variant="outlined"
                    onClick={() => {
                      setActiveQ(values.questions.length);
                      push(defaultQuestion);
                    }}
                    fullWidth
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    <FormattedMessage
                      defaultMessage="Add Question"
                      description="Add Question Action"
                      id="E5C2fk"
                    />
                  </LoadingButton>
                </div>
              </ClickAwayListener>
            )}
          </FieldArray>
          <Paper elevation={2} sx={{ my: 2, p: 2 }}>
            <FormGroup>
              <FormLabel component="legend" required>
                <FormattedMessage
                  defaultMessage="Poll Name"
                  description="Input title: Poll Name"
                  id="6DXpLR"
                />
              </FormLabel>
              <TextField
                id="name"
                label={intl.formatMessage({
                  description: "Input label: Poll Name",
                  defaultMessage: "Name",
                  id: "bZGgRV",
                })}
                placeholder={intl.formatMessage({
                  description: "Input placeholder: Poll Name",
                  defaultMessage: "Enter poll name",
                  id: "wY6zTl",
                })}
                multiline
                variant="outlined"
                margin="dense"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={
                  touched.name && Boolean(errors.name)
                    ? errors.name
                    : intl.formatMessage({
                        description: "Input Helper Text: Poll Name",
                        defaultMessage:
                          "Not public. Used as a reference to this poll inside the app",
                        id: "4tDaMj",
                      })
                }
              />
            </FormGroup>
          </Paper>
          {dirty && isValid && (
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              type="submit"
              fullWidth
              endIcon={<DoubleArrowIcon />}
              sx={{ justifyContent: "space-between" }}
              size="large"
            >
              {isEditMode ? (
                <FormattedMessage
                  defaultMessage="Save Poll"
                  description="Save Poll Action"
                  id="ksFdbI"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Create Poll"
                  description="Create Poll Action"
                  id="AggxyV"
                />
              )}
            </LoadingButton>
          )}
        </Box>
      </Container>
    </FormikProvider>
  );
}

export default CreatePoll;

import React from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

function OutroStep({ proceed, onComplete }) {
  React.useEffect(() => {
    if (proceed === true) {
      onComplete();
    }
  }, [proceed, onComplete]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        component="div"
        sx={{ textAlign: "center" }}
        gutterBottom
      >
        <FormattedMessage
          defaultMessage="All Done 🎉"
          description="Onboarding Outro Title"
          id="zhlJZx"
        />
      </Typography>
      <Typography variant="body1" component="div" sx={{ textAlign: "center" }}>
        <FormattedMessage
          defaultMessage="<p>Thank you for taking time to fill in your profile. Now everything is setup for you.</p><p>Tap „Done“ in the lower right corner of the screen to be redirected to the App.</p>"
          description="Onboarding Intro Description"
          id="dx0ogd"
          values={{
            p: (chunks) => <p>{chunks}</p>,
          }}
        />
      </Typography>
    </Box>
  );
}

export default OutroStep;

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Stack,
  Paper,
  Typography,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  FormHelperText,
  Divider,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

function CreditsQtyRadio({ qty, active }) {
  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: { py: 2, px: 6 },
        [theme.breakpoints.down('md')]: { py: 1.5, px: 1.5 },
        py: 3,
        px: 9,
        borderColor: active ? theme.palette.primary.main : null,
      })}
    >
      <Typography variant="button" sx={{ fontWeight: active ? "900" : null }}>
        {qty}
      </Typography>
    </Paper>
  );
}

const VAT_PERCENTAGE = 0.2;
const CREDIT_QTY_OPTIONS = [250, 1000, 2000];

function TopUp() {
  const intl = useIntl();
  const [amounts, setAmounts] = React.useState({});

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      credits: CREDIT_QTY_OPTIONS[0],
      enableATU: false,
    },
    validationSchema: Yup.object({
      credits: Yup.number()
        .min(500)
        .required(
          intl.formatMessage({
            description: "Top Up: Credit quantity is required",
            defaultMessage: "Credit quantity is required",
            id: "1V3e5g",
          }),
        ),
      enableATU: Yup.boolean().required(),
    }),
    onSubmit: async ({ credits, enableATU }) => {
      // @TODO
      console.log(credits, enableATU);
    },
  });

  React.useEffect(() => {
    const price = Number(values.credits) <= 500 ? 0.03 : 0.02;
    const subtotal = Number(values.credits) * price;
    const vat = subtotal * VAT_PERCENTAGE;
    const total = subtotal + vat;
    setAmounts({ price, subtotal, vat, total });
  }, [values]);

  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Typography variant="h3" gutterBottom>
        <FormattedMessage
          defaultMessage="Top Up Credits"
          description="Top Up Credits Title"
          id="AljLzf"
        />
      </Typography>
      <Paper sx={{ p: 2 }}>
        <Stack
          onSubmit={handleSubmit}
          component="form"
          autoComplete="off"
          noValidate
          spacing={2}
        >
          <FormGroup>
            <FormLabel id="credits-label">
              <FormattedMessage
                defaultMessage="Choose Quantity"
                description="Top Up Label: Choose Quantity"
                id="UMKy+I"
              />
            </FormLabel>
            <RadioGroup
              aria-labelledby="credits-label"
              name="credits"
              value={values.credits}
              onChange={handleChange}
              row
            >
              {CREDIT_QTY_OPTIONS.map((qty, key) => (
                <Radio
                  key={key}
                  sx={{
                    "&:hover": {
                      borderRadius: "4px",
                    },
                  }}
                  value={qty}
                  icon={<CreditsQtyRadio qty={qty} />}
                  checkedIcon={<CreditsQtyRadio qty={qty} active />}
                />
              ))}
            </RadioGroup>
          </FormGroup>
          <FormGroup>
            <FormLabel id="auto-top-up-label">
              <FormattedMessage
                defaultMessage="Auto Top Up"
                description="Top Up Label: Auto Top Up"
                id="gk3fil"
              />
            </FormLabel>
            <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
              <FormControlLabel
                aria-labelledby="credits-label"
                control={
                  <Switch
                    checked={values.enableATU}
                    onChange={handleChange}
                    name="enableATU"
                  />
                }
                label={intl.formatMessage(
                  {
                    defaultMessage:
                      "Auto top up is {enabled, select, 0 {disabled} 1 {enabled} other {}}",
                    description: "Top Up: Credit quantity is required",
                    id: "/+Id4z",
                  },
                  { enabled: Number(values.enableATU) },
                )}
              />
              <FormHelperText>
                <FormattedMessage
                  defaultMessage="If you choose to enable auto top up you are going to be charged the same sum every month and received the selected amount of credits into your account automatically"
                  description="Auto TOP UP Disclaimer"
                  id="5Y4GwP"
                />
              </FormHelperText>
            </Paper>
          </FormGroup>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="Credit Cost"
                  description="Top Up Bill: Credit Cost"
                  id="fNYNKt"
                />
              </Typography>
              <Typography variant="overline">
                <FormattedMessage
                  defaultMessage="{amount, number, ::currency/BGN}"
                  description="Currency amount"
                  id="byecDR"
                  values={{ amount: amounts.price }}
                />
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="Qty"
                  description="Top Up Bill: Qty"
                  id="m0bgeG"
                />
              </Typography>
              <Typography variant="overline">{values.credits}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="Subtotal"
                  description="Top Up Bill: Subtotal"
                  id="Jv4kgx"
                />
              </Typography>
              <Typography variant="overline">
                <FormattedMessage
                  defaultMessage="{amount, number, ::currency/BGN}"
                  description="Currency amount"
                  id="byecDR"
                  values={{ amount: amounts.subtotal }}
                />
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="VAT (20%)"
                  description="Top Up Bill: VAT"
                  id="YMWcgc"
                />
              </Typography>
              <Typography variant="overline">
                <FormattedMessage
                  defaultMessage="{amount, number, ::currency/BGN}"
                  description="Currency amount"
                  id="byecDR"
                  values={{ amount: amounts.vat }}
                />
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                <FormattedMessage
                  defaultMessage="Total Amount"
                  description="Top Up Bill: Total Amount"
                  id="R4uLP7"
                />
              </Typography>
              <Typography variant="overline">
                <FormattedMessage
                  defaultMessage="{amount, number, ::currency/BGN}"
                  description="Currency amount"
                  id="byecDR"
                  values={{ amount: amounts.total }}
                />
              </Typography>
            </Stack>
          </Stack>
          {!!values.credits && (
            <FormGroup>
              <Button
                type="submit"
                variant="contained"
                endIcon={<DoubleArrowIcon />}
                sx={{ justifyContent: "space-between" }}
                size={"large"}
              >
                <FormattedMessage
                  defaultMessage="Pay {total, number, ::currency/BGN} {monthly, select, 0 {} 1 { monthly} other {}}"
                  description="Top up form submit button"
                  id="W48vsy"
                  values={{
                    total: amounts.total,
                    monthly: Number(values.enableATU),
                  }}
                />
              </Button>
            </FormGroup>
          )}
        </Stack>
      </Paper>
    </Container>
  );
}

export default TopUp;

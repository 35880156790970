import { useState, useEffect, useContext } from "react";
import { DataStore } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocation, useNavigate, Link, Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  AppBar,
  SwipeableDrawer,
  Toolbar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem,
  Fab,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Fade,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BadgeIcon from "@mui/icons-material/Badge";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import { styled } from "@mui/material/styles";

import Logo from "../../../../assets/logo.svg";
import { ClientContext } from "../../../../context";

const miniDrawerWidth = 56;
const drawerWidth = 360;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    margin: 0,
    padding: 0,
    paddingBottom: "env(safe-area-inset-bottom)",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down('md')]: {
      "&:after": {
        display: "block",
        ...theme.mixins.toolbar,
        content: '""',
      },
    },

    [theme.breakpoints.between("sm", 'xl')]: {
      "&:before": {
        display: "block",
        ...theme.mixins.toolbar,
        content: '""',
      },
    },

    [theme.breakpoints.up("lg")]: {
      "&:before": {
        display: "block",
        ...theme.mixins.toolbar,
        content: '""',
      },
    },
  }),
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('md')]: {
    justifyContent: "flex-start",
  },
  justifyContent: "flex-end",
}));

// at this stage we have 'logged in user' and 'activeClient'
function AppLayout({ children }) {
  const authenticator = useAuthenticator();
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", 'xl'));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [open, setOpen] = useState(false);
  const { active: client } = useContext(ClientContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAppMenuOpen = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(menu);
  };

  const handleAppMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const signOutHandler = async () => {
    await DataStore.clear();
    authenticator.signOut();
    navigate("/");
  };

  const ListItems = ({ items }) =>
    items.map(({ path, icon, primary, secondary }, key) => (
      <ListItem
        key={key}
        button
        selected={location.pathname === path}
        component={Link}
        to={path}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    ));

  const AppBarMenu = (props) => {
    return (
      <Menu
        id={props.id}
        aria-controls={props.ariaControls}
        anchorEl={anchorEl}
        open={openMenu === props.id}
        onClose={handleAppMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        TransitionComponent={Fade}
        PaperProps={{
          elevation: 0,
          sx: (theme) => ({
            width: "100%",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            [theme.breakpoints.up("sm")]: {
              width: "240px",
              mt: 2,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          }),
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {props.children}
      </Menu>
    );
  };

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${miniDrawerWidth}px + 1px)`,
  });

  useEffect(() => {
    if (isMobile || isTablet) {
      handleDrawerClose();
    }
  }, [location, isMobile, isTablet]);

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        height: "100%",
      }}
    >
      <AppBar
        position="fixed"
        sx={(theme) => ({
          paddingBottom: "env(safe-area-inset-bottom)",
          [theme.breakpoints.down('md')]: {
            top: "auto",
            bottom: 0,
          },
        })}
        open={open}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          {!isMobile && location.pathname !== "/create/poll" && (
            <Tooltip
              title={intl.formatMessage({
                defaultMessage: "Create Poll",
                description: "Create Actions Tooltip",
                id: "BbZ5n2",
              })}
            >
              <IconButton color="inherit" component={Link} to="/create/poll" size="large">
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={intl.formatMessage({
              defaultMessage: "Account Actions",
              description: "Account Actions Tooltip",
              id: "9g9+gL",
            })}
          >
            <IconButton
              id="moreMenuTrigger"
              aria-controls="moreMenu"
              aria-haspopup="true"
              aria-expanded={true}
              color="inherit"
              onClick={(event) => {
                handleAppMenuOpen(event, "moreMenu");
              }}
              size="large">
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <AppBarMenu id="moreMenu" ariaControls="moreMenuTrigger">
          <MenuItem
            onClick={() => {
              handleAppMenuClose();
              navigate("/company");
            }}
          >
            <ListItemIcon>
              <BadgeIcon />
            </ListItemIcon>
            <ListItemText
              primary={client.name}
              secondary={intl.formatMessage({
                defaultMessage: "Update company details",
                description: "Account Actions: Update company details",
                id: "mskxan",
              })}
            />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleAppMenuClose();
              navigate("/settings");
            }}
          >
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Settings"
                description="Account Actions: Settings"
                id="aJbubT"
              />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAppMenuClose();
              navigate("/credits/history");
            }}
          >
            <ListItemIcon>
              <HistoryIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Credit History"
                description="Account Actions: Credit History"
                id="aW3sKv"
              />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleAppMenuClose();
              navigate("/help");
            }}
          >
            <ListItemIcon>
              <HelpCenterIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Help Center"
                description="Help Center Action"
                id="J8dZDm"
              />
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={signOutHandler}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Sign Out"
                description="Account Actions: Sign Out"
                id="9zjAby"
              />
            </ListItemText>
          </MenuItem>
        </AppBarMenu>
      </AppBar>
      <SwipeableDrawer
        sx={(theme) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: "nowrap",
          boxSizing: "border-box",

          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            justifyContent: "space-between",
            paddingBottom: "env(safe-area-inset-bottom)",
          },

          [theme.breakpoints.down('md')]: {
            "& .MuiDrawer-paper": {
              width: "85vw",
              maxWidth: drawerWidth,
              flexDirection: "column-reverse",
            },
          },

          [theme.breakpoints.between("sm", 'xl')]: {
            ...(open && {
              ...openedMixin(theme),
              "& .MuiDrawer-paper": openedMixin(theme),
            }),
            ...(!open && {
              ...closedMixin(theme),
              "& .MuiDrawer-paper": closedMixin(theme),
            }),
          },

          [theme.breakpoints.up("lg")]: {
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          },
        })}
        variant={isMobile ? `temporary` : `permanent`}
        anchor="left"
        open={open}
        onOpen={() => handleDrawerOpen()}
        onClose={() => handleDrawerClose()}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "column",
              [theme.breakpoints.down('md')]: {
                flexDirection: "column-reverse",
              },
            };
          }}
        >
          {isTablet && (
            <DrawerHeader>
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} size="large">
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
            </DrawerHeader>
          )}
          {isMobile && <Divider />}
          <Box>
            {!isTablet || (isTablet && open) ? (
              <Box
                sx={{
                  textAlign: "center",
                  marginY: theme.spacing(2),
                  [theme.breakpoints.up("sm")]: {
                    marginY: theme.spacing(4),
                  },
                }}
              >
                <Link to="/">
                  <img alt="logo" src={Logo} width="200" />
                </Link>
              </Box>
            ) : null}
            <List>
              <ListItems
                items={[
                  {
                    path: "/",
                    icon: <DashboardIcon />,
                    primary: intl.formatMessage({
                      defaultMessage: "Assistant",
                      description: "Main Nav: Assistant",
                      id: "l/CDz3",
                    }),
                    secondary: intl.formatMessage({
                      defaultMessage: "Personal Dashboard",
                      description: "Main Nav Secondary: Assistant",
                      id: "ilknXT",
                    }),
                  },
                  {
                    path: "/monitor",
                    icon: <MonitorHeartIcon />,
                    primary: intl.formatMessage({
                      defaultMessage: "Monitor",
                      description: "Main Nav: Monitor",
                      id: "QulTxK",
                    }),
                  },
                ]}
              />
              <Divider />
              <ListItems
                items={[
                  {
                    path: "/help",
                    icon: <HelpCenterIcon />,
                    primary: intl.formatMessage({
                      defaultMessage: "Help Center",
                      description: "Help Center Action",
                      id: "J8dZDm",
                    }),
                    secondary: intl.formatMessage({
                      defaultMessage: "Frequently Asked Questions",
                      description: "Main Nav Secondary: Help Center",
                      id: "KlMdq9",
                    }),
                  },
                ]}
              />
            </List>
          </Box>
        </Box>
        {!isTablet || (isTablet && open) ? (
          <Card
            sx={(theme) => ({
              margin: theme.spacing(2),
              [theme.breakpoints.up("lg")]: {
                margin: theme.spacing(3),
              },
            })}
          >
            <CardContent>
              <Typography
                fontSize={isDesktop ? `16px` : isTablet ? `14px` : `12px`}
                variant="overline"
                color="text.secondary"
                gutterBottom
              >
                <FormattedMessage
                  defaultMessage="Account Credits"
                  description="Account Credits Box Title"
                  id="CeIlYH"
                />
              </Typography>
              <Typography
                variant={isDesktop ? `h3` : isTablet ? "h4" : "h4"}
                component="div"
              >
                {client.credits}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" component={Link} to="/credits/top-up">
                <FormattedMessage
                  defaultMessage="Buy More"
                  description="Buy More Action"
                  id="ycd4DT"
                />{" "}
                &rarr;
              </Button>
            </CardActions>
          </Card>
        ) : null}
      </SwipeableDrawer>
      <Main open={open}>
        <Outlet />
        {/* @TODO add Footer */}
        {["/monitor", "/help"].includes(location.pathname) && isMobile && (
          <Fab
            sx={(theme) => ({
              position: "fixed",
              bottom: `calc(56px + ${theme.spacing(
                3,
              )} + env(safe-area-inset-bottom))`,
              right: theme.spacing(3),
            })}
            color="primary"
            aria-label="add"
            id="createMenuTrigger"
            aria-controls="createMenu"
            aria-haspopup="true"
            aria-expanded={true}
            component={Link}
            to="/create/poll"
          >
            <AddIcon />
          </Fab>
        )}
      </Main>
    </Box>
  );
}

export default AppLayout;

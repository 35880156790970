import React from "react";
import { DataStore } from "aws-amplify";
import {
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import useClipboard from "react-use-clipboard";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { Link, LinkTypesEnum, Poll } from "../../../../models";

function ShareViaLink({ id }) {
  const [loading, setLoading] = React.useState(true);
  const [link, setLink] = React.useState(null);
  const [isCopied, setCopied] = useClipboard(link, {
    successDuration: 1500,
  });

  React.useEffect(() => {
    DataStore.query(Poll, id).then(async (_poll) => {
      if (_poll) {
        const links = (await DataStore.query(Link)).filter(
          (l) => l.type === LinkTypesEnum.FAUCET && l.pollID === _poll.id,
        );
        setLink(`${process.env.REACT_APP_BASE_URL}/${links[0]?.id}`);
      }
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return `Loading...`;
  }

  if (!link) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <Paper
        component="form"
        elevation={2}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputBase
          id="poll-link"
          sx={{ ml: 1, flex: 1 }}
          value={link}
          readOnly
        />
        {/* <Tooltip
          title={intl.formatMessage({
            defaultMessage: "Open voting page in new tab",
            description: "Poll: Open voting page in new tab tooltip",
            id: "xjX8Nx",
          })}
        > */}
        <IconButton
          href={link}
          target="_blank"
          color="secondary"
          sx={{ p: "10px" }}
          aria-label="Open"
          size="large">
          <OpenInNewIcon />
        </IconButton>
        {/* </Tooltip> */}
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        {/* <Tooltip
          title={intl.formatMessage({
            defaultMessage: "Copy voting link to clipboard",
            description: "Poll: Copy voting link to clipboard tooltip",
            id: "3FXKPV",
          })}
        >*/}
        <IconButton
          onClick={() => {
            setCopied(true);
          }}
          color="primary"
          sx={{ p: "10px" }}
          aria-label="Open"
          size="large">
          <ContentCopyIcon />
        </IconButton>
        {/* </Tooltip> */}
      </Paper>
      {isCopied && (
        <FormHelperText id="poll-link-helper">
          <FormattedMessage
            defaultMessage="Copied to clipboard"
            description="Confirmation: Copied to clipboard"
            id="DqIikh"
          />
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default ShareViaLink;

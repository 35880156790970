import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import {
  Container,
  Paper,
  Stack,
  Link,
  Snackbar,
  Alert,
  Button,
  Divider,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl, FormattedMessage } from "react-intl";
import DividerIcon from "@mui/icons-material/ExpandCircleDown";

import {
  FullscreenLoader,
  CompanyWidget,
  QuestionWidget,
} from "../../../../components";
import { registerVote, triggerCounter } from "../../../../graphql/mutations";
import { getPoll, getLinkVotes } from "../../../../graphql/queries";

const SNACKBARS = { SUCCESS: "success", ERROR: "error" };

function FeedbackModule(props) {
  const { pathname } = useLocation();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [poll, setPoll] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [votes, setVotes] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { pollID } = props;
  const linkID = pathname.substring(1);

  const handleVote = async (questionID, _vote) => {
    if (poll.Client.credits > 0) {
      setSnackbarOpen(SNACKBARS.SUCCESS);
    }

    setVotes((_votes) => {
      const updatedVotes = { ..._votes };
      updatedVotes[questionID] = _vote;

      return updatedVotes;
    });

    try {
      await API.graphql({
        query: registerVote,
        authMode: "AWS_IAM",
        variables: {
          pollID,
          questionID,
          linkID,
          vote: _vote,
        },
      });
    } catch ({ errors }) {
      if (errors[0].message === "ERR:V:1") {
        setSnackbarOpen(SNACKBARS.ERROR);
        setVotes((_votes) => {
          const updatedVotes = { ..._votes };
          updatedVotes[questionID] = 0;

          return updatedVotes;
        });
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVotingUndo = () => {
    handleSnackbarClose();
  };

  useEffect(() => {
    API.graphql({
      query: getPoll,
      authMode: "AWS_IAM",
      variables: {
        id: pollID,
      },
    }).then(async ({ data: { getPoll: _poll } }) => {
      const {
        data: {
          getLinkVotes: { items: voteItems },
        },
      } = await API.graphql({
        query: getLinkVotes,
        authMode: "AWS_IAM",
        variables: {
          linkID,
        },
      });
      const votes = {};
      voteItems.forEach((_item) => (votes[_item.questionID] = _item.vote));
      setVotes(votes);
      setPoll(_poll);
      setQuestions(_poll.questions.items.filter((_q) => !Boolean(_q._deleted)));
      setLoading(false);
    });
  }, [pollID, linkID]);

  useEffect(() => {
    API.graphql({
      query: triggerCounter,
      authMode: "AWS_IAM",
      variables: {
        counters: [
          JSON.stringify({
            period: "1d",
            type: "imp",
            sub: `Poll:${pollID}`,
          }),
        ],
      },
    });
  }, [pollID]);

  if (loading) {
    return (
      <FullscreenLoader
        caption={intl.formatMessage({
          description: "Loading poll details loading message",
          defaultMessage: "Loading poll details...",
          id: "mW+x3M",
        })}
      />
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 3 }}>
      <Stack spacing={3}>
        <Paper elevation={2} sx={{ p: isMobile ? 2 : 8 }}>
          <Stack spacing={isMobile ? 2 : 4}>
            {poll.Client.credits <= 0 && (
              <Box>
                <Alert severity="warning">
                  <FormattedMessage
                    defaultMessage="This poll is currently not active."
                    description="Feedback Module: Client Out of Credits Alert Message"
                    id="YpCQcg"
                  />
                </Alert>
              </Box>
            )}
            {questions.map((question, index) => (
              <React.Fragment key={index}>
                <QuestionWidget
                  question={question}
                  onVote={handleVote}
                  value={votes[question.id]}
                />
                {index + 1 < questions.length && (
                  <Divider>
                    <DividerIcon sx={{ display: "flex" }} />
                  </Divider>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Paper>
        <CompanyWidget company={poll.Client} />
        <Stack spacing={1}>
          <Link href="https://xtun.io/" target="_blank">
            <FormattedMessage
              defaultMessage="How the rating system works?"
              description="Feedback Module: Quick Links"
              id="ayL0mb"
            />
          </Link>
          <Link href="https://xtun.io/" target="_blank">
            <FormattedMessage
              defaultMessage=" Why I'm seeing this?"
              description="Feedback Module: Quick Links"
              id="7oOHTt"
            />
          </Link>
          <Link href="https://xtun.io/" target="_blank">
            <FormattedMessage
              defaultMessage=" What is XTUN? Learn more about us."
              description="Feedback Module: Quick Links"
              id="EV2P1d"
            />
          </Link>
        </Stack>
      </Stack>
      <Snackbar
        open={snackbarOpen === SNACKBARS.SUCCESS}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
          action={
            <Button color="inherit" size="small" onClick={handleVotingUndo}>
              <FormattedMessage
                description="Undo Action"
                defaultMessage="Undo"
                id="slaXLL"
              />
            </Button>
          }
        >
          <FormattedMessage
            description="Vote Success Message"
            defaultMessage="Thanks! You voted successfully."
            id="pUKHIn"
          />
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen === SNACKBARS.ERROR}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          <FormattedMessage
            description="Client out of credits. Vote not counted."
            defaultMessage="Your vote cannot be counted at this time. Please ask {client} to resolve the control panel issue."
            id="6JvRYt"
            values={{ client: poll.Client.name }}
          />
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default FeedbackModule;

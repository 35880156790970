import React from "react";
import { DataStore } from "aws-amplify";
import QRCode from "react-qr-code";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import { Link, LinkTypesEnum, Poll } from "../../../../models";

function ShareViaQRForm({ id }) {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [link, setLink] = React.useState(null);

  const handleQRDownload = () => {
    const scaleFactor = 4;
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;
      ctx.scale(scaleFactor, scaleFactor);
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "XTUN-Poll-QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  React.useEffect(() => {
    DataStore.query(Poll, id).then(async (_poll) => {
      if (_poll) {
        const links = (await DataStore.query(Link)).filter(
          (l) => l.type === LinkTypesEnum.FAUCET && l.pollID === _poll.id,
        );
        setLink(`${process.env.REACT_APP_BASE_URL}/${links[0]?.id}`);
      }
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return `Loading...`;
  }

  if (!link) {
    return null;
  }

  return (
    <Stack alignItems="center" spacing={2}>
      <QRCode id="QRCode" value={link} fgColor={theme.palette.primary.main} />
      <LoadingButton
        endIcon={<DownloadForOfflineIcon />}
        onClick={handleQRDownload}
      >
        <FormattedMessage
          defaultMessage="Download"
          description="Download Action"
          id="woGodB"
        />
      </LoadingButton>
    </Stack>
  );
}

export default ShareViaQRForm;

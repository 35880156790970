import React from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import WelcomeImage from "../../../../../assets/welcome.png";

function IntroStep({ proceed, onComplete }) {
  React.useEffect(() => {
    if (proceed === true) {
      onComplete({ client: { oStep: 1 } });
    }
  }, [proceed, onComplete]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={WelcomeImage} width="100%" alt="" />
      <Typography
        variant="h2"
        component="div"
        sx={{ textAlign: "center" }}
        gutterBottom
      >
        <FormattedMessage
          defaultMessage="Hello 👋"
          description="Onboarding Intro Title"
          id="QP2Zrc"
        />
      </Typography>
      <Typography variant="body1" component="div" sx={{ textAlign: "center" }}>
        <FormattedMessage
          defaultMessage="<p>Almost ready. We just need a few more details in order to be able to to set up your profile.</p><p>Tap „Next“ in the lower right corner of the screen to continue.</p>"
          description="Onboarding Intro Description"
          id="xp+q35"
          values={{
            p: (chunks) => <p>{chunks}</p>,
          }}
        />
      </Typography>
    </Box>
  );
}

export default IntroStep;

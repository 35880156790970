import React from "react";
import { DataStore, SortDirection, Predicates } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl, FormattedMessage } from "react-intl";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

import { Service } from "../../../../../models";

function AddServicesStep({ proceed, goForward }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();
  const [services, setServices] = React.useState(null);

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
      },
      validationSchema: Yup.object({
        name: Yup.string().required(
          intl.formatMessage({
            description: "Add Services: Service name is required.",
            defaultMessage: "Service name is required.",
            id: "dBp8mb",
          }),
        ),
      }),
      onSubmit: async ({ name }, actions) => {
        actions.resetForm();
        const _service = await DataStore.save(
          new Service({
            name,
          }),
        );
        setServices((_services) => {
          const list = [..._services];
          list.unshift(_service);
          return list;
        });
      },
    });

  const handleServiceRemove = async ({ id }) => {
    const _service = await DataStore.query(Service, id);
    DataStore.delete(_service);
    setServices(services.filter(({ id: _id }) => _id !== id));
  };

  const fetchServices = React.useCallback(async () => {
    const _services = await DataStore.query(Service, Predicates.ALL, {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
    });
    setServices(_services);
  }, []);

  React.useEffect(() => {
    if (proceed === true) {
      goForward();
    }
  }, [proceed, goForward]);

  React.useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <Container sx={{ py: 3, px: isMobile ? 0 : 2 }}>
      <Typography variant="h3" component="div" gutterBottom>
        <FormattedMessage
          description="Onboarding Add Services Title"
          defaultMessage="Add Your Services"
          id="3/+Xap"
        />
      </Typography>
      <Paper elevation={isMobile ? 0 : 1} sx={{ p: isMobile ? 0 : 2 }}>
        <Typography variant="h6" gutterBottom component="div">
          <FormattedMessage
            description="Onboarding Add Services Subtitle"
            defaultMessage="Please, add the services your company is performing"
            id="BLFtuX"
          />
        </Typography>
        <Box
          component="form"
          autoComplete="off"
          sx={{ mb: 3 }}
          onSubmit={handleSubmit}
          noValidate
        >
          <FormControl
            variant="outlined"
            error={touched.name && Boolean(errors.name)}
            fullWidth
          >
            <InputLabel htmlFor="name">
              <FormattedMessage
                description="Input Label: Service Name"
                defaultMessage="Service Name"
                id="zeoWMl"
              />
            </InputLabel>
            <OutlinedInput
              id="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              label={intl.formatMessage({
                description: "Input Label: Service Name",
                defaultMessage: "Service Name",
                id: "zeoWMl",
              })}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    aria-label="toggle password visibility"
                    onClick={handleSubmit}
                    endIcon={<AddCircleIcon />}
                  >
                    <FormattedMessage
                      description="Add Service Action"
                      defaultMessage="Add Service"
                      id="BVoBgp"
                    />
                  </Button>
                </InputAdornment>
              }
              aria-describedby="service-helper-text"
              inputProps={{
                "aria-label": "Service",
              }}
            />
            <FormHelperText id="service-helper-text">
              {touched.name && !!errors.name ? (
                errors.name
              ) : (
                <FormattedMessage
                  description="Input Label Helper Text: Service Name"
                  defaultMessage={`Fill in a name of your service and then press "ADD SERVICE" to create it`}
                  id="gayjTy"
                />
              )}
            </FormHelperText>
          </FormControl>
        </Box>
        {services && services.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom component="div">
              <FormattedMessage
                description="Onboarding Add Services: Added Services"
                defaultMessage="List of Your Services"
                id="9eXwUf"
              />
            </Typography>
            <Paper variant={isMobile ? "elevation" : null}>
              <List>
                {services.map((_service) => (
                  <ListItem
                    key={_service.id}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleServiceRemove(_service)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText>{_service.name}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </>
        )}
      </Paper>
    </Container>
  );
}

export default AddServicesStep;

import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import ReminderHeaderImage from "../../../../../assets/reminder-header.jpg";
import { ClientContext } from "../../../../../context/ClientContext";

function TopUpReminderCard() {
  const { active: client } = React.useContext(ClientContext);
  return (
    <Card elevation={2}>
      <CardMedia component="img" height={240} image={ReminderHeaderImage} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <FormattedMessage
            defaultMessage="Need More Credits"
            description="Top Up Reminder Widget Title"
            id="dHtnts"
          />
        </Typography>
        <Typography gutterBottom variant="body1" color="text.primary">
          <FormattedMessage
            defaultMessage="{credits, plural, =0 {You don't have any credits left} one {You have one credit left} other {You have {credits} more credits left}}"
            description="Top Up Reminder Widget Available Credits"
            id="5yQ6zq"
            values={{ credits: client.credits }}
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            defaultMessage="It looks like soon you're going to run out of credits. If you don't want your polls to stop working, please top up your account now."
            description="Top Up Reminder Widget subtitle"
            id="ZpiJsO"
          />
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="outlined"
          component={Link}
          to="/credits/top-up"
        >
          <FormattedMessage
            defaultMessage="Top Up Credits"
            description="Top Up Credits Action"
            id="9llHIc"
          />
        </Button>
        <Button size="small">
          <FormattedMessage
            defaultMessage="Learn More"
            description="Learn More Action"
            id="pj6FsA"
          />
        </Button>
      </CardActions>
    </Card>
  );
}

export default TopUpReminderCard;

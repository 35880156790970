import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Rating,
  Chip,
  FormHelperText,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

function SentimentIconContainer(props) {
  const { value, ...other } = props;

  const icons = {
    1: {
      icon: <ThumbDownOffAltIcon sx={{ mr: 1 }} />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <ThumbUpOffAltIcon sx={{ mr: 1 }} />,
      label: "Neutral",
    },
    3: {
      icon: <FavoriteBorderIcon />,
      label: "Very Satisfied",
    },
  };

  return <span {...other}>{icons[value].icon}</span>;
}

function NPSIconContainer(props) {
  const { value } = props;

  const icons = [];
  for (let i = 0; i <= 10; i++) {
    icons.push({
      icon: <Chip size="small" label={i} />,
      label: i,
    });
  }

  return icons[value].icon;
}

function PollWidgetRadioGroup({ name, widget, onChange }) {
  return (
    <RadioGroup
      aria-label="Widget"
      name={name}
      value={widget}
      onChange={onChange}
    >
      <FormControlLabel
        value="STARS"
        control={<Radio />}
        label={<Rating value={3} readOnly />}
        disableTypography
      />
      {widget === "STARS" && (
        <FormHelperText>
          <FormattedMessage
            defaultMessage="{widget, select, STARS {Measures Rating} SENTIMENT {Measures Sentiment} other {Other}}"
            description="Rating widget description"
            id="8qXOjt"
            values={{
              widget: widget,
            }}
          />
        </FormHelperText>
      )}
      <FormControlLabel
        value="SENTIMENT"
        control={<Radio />}
        label={
          <Rating
            value={2}
            IconContainerComponent={SentimentIconContainer}
            highlightSelectedOnly
            max={3}
            readOnly
          />
        }
        disableTypography
      />
      {widget === "SENTIMENT" && (
        <FormHelperText>
          <FormattedMessage
            defaultMessage="{widget, select, STARS {Measures Rating} SENTIMENT {Measures Sentiment} other {Other}}"
            description="Rating widget description"
            id="8qXOjt"
            values={{
              widget: widget,
            }}
          />
        </FormHelperText>
      )}
      {false && (
        <>
          <FormControlLabel
            value="NPS"
            control={<Radio />}
            label={
              <Rating
                sx={(theme) => ({
                  gap: theme.spacing(0.5),
                  [theme.breakpoints.down("md")]: {
                    transform: "scale(0.85)",
                    transformOrigin: "0 50%",
                  },
                })}
                value={7}
                IconContainerComponent={NPSIconContainer}
                max={10}
                readOnly
              />
            }
            disableTypography
          />
          {widget === "NPS" && (
            <FormHelperText>
              <FormattedMessage
                defaultMessage="Measures Net Promoter Score"
                description="NPS widget description"
                id="aRmWvx"
              />
            </FormHelperText>
          )}
        </>
      )}
    </RadioGroup>
  );
}

export default PollWidgetRadioGroup;

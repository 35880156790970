import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { useIntl } from "react-intl";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";

import { Service } from "../../../../models";

const filter = createFilterOptions();

function PollBusinessAspectSelector({ value, name, error, onChange, onBlur }) {
  const intl = useIntl();
  const [loadingServices, setLoadingServices] = useState(false);
  const [services, setServices] = useState([]);

  useEffect(() => {
    setLoadingServices(true);
    DataStore.query(Service).then((_services) => {
      setServices(_services);
      setLoadingServices(false);
    });
  }, []);

  return (
    <Autocomplete
      loading={loadingServices}
      loadingText={intl.formatMessage({
        description: "Services Autocomplete: Loading State",
        defaultMessage: "Loading Services...",
        id: "ac5bvN",
      })}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(
          (option) =>
            inputValue.trim().toLowerCase() === option.name.toLowerCase(),
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      options={services}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }

        return option.name;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={intl.formatMessage({
            description: "Input label: Poll Service",
            defaultMessage: "Service",
            id: "W2o6V6",
          })}
          error={error}
        />
      )}
      freeSolo
      id={name}
      name={name}
      value={value}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          const serviceIdx = services.findIndex(
            (option) =>
              newValue.trim().toLowerCase() === option.name.toLowerCase(),
          );
          const service =
            serviceIdx > -1
              ? services[serviceIdx]
              : new Service({ name: newValue });
          onChange(service);
        } else if (newValue && newValue.inputValue) {
          onChange(
            new Service({
              name: newValue.inputValue,
            }),
          );
        } else {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
}

export default PollBusinessAspectSelector;

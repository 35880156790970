import { useIntl } from "react-intl";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import BaseActionCard from "./BaseActionCard";

function CreatePollActionCard() {
  const intl = useIntl();

  return (
    <BaseActionCard
      title={intl.formatMessage({
        defaultMessage: "Create a New Poll",
        description: "Create Poll Card Widget Title",
        id: "cMdp0y",
      })}
      subheader={intl.formatMessage({
        defaultMessage:
          "Start collecting feedback from your customers in just a few easy steps.",
        description: "Create Poll Card Widget Subheader",
        id: "Q/a6Dl",
      })}
      action={{
        to: "/create/poll",
        title: intl.formatMessage({
          defaultMessage: "Create Poll",
          description: "Create Poll Action",
          id: "AggxyV",
        }),
      }}
      icon={<AddCircleOutlineIcon fontSize="large" />}
    />
  );
}

export default CreatePollActionCard;

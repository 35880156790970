import { useIntl } from "react-intl";
import ServicesIcon from "@mui/icons-material/MedicalServices";

import BaseActionCard from "./BaseActionCard";

function SetupBusinessActionCard() {
  const intl = useIntl();

  return (
    <BaseActionCard
      title={intl.formatMessage({
        defaultMessage: "Setup Your Business",
        description: "Setup Business Card Title",
        id: "C1OL6f",
      })}
      subheader={intl.formatMessage({
        defaultMessage:
          "Do you have employees, what is your working schedule? Are offering services or selling products? Add your business details here.",
        description: "Setup Business Card Subheader",
        id: "DiD+zS",
      })}
      action={{
        to: "/business",
        title: intl.formatMessage({
          defaultMessage: "Setup Business",
          description: "Setup Business Action",
          id: "zc/Vfw",
        }),
      }}
      icon={<ServicesIcon fontSize="large" />}
    />
  );
}

export default SetupBusinessActionCard;

import { Container, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { ScoreWidget, TrendWidget } from "../components";
const WIP = true;

function Monitor() {
  if (WIP) {
    return (
      <Container maxWidth="xl" sx={{ my: 2 }}>
        <Typography variant="overline" tag="p" gutterBottom>
          <FormattedMessage
            defaultMessage="Coming Soon"
            description="Coming Soon"
            id="+aycNd"
          />
        </Typography>
        <Typography variant="h4" tag="div">
          <FormattedMessage
            defaultMessage="Monitor"
            description="Monitor Title"
            id="zO9x2Y"
          />
        </Typography>
        <Typography variant="body1" tag="div">
          <FormattedMessage
            defaultMessage="We are currently working on this portion of the app. We are going to let you know once it is ready for you to try out."
            description="Monitor Coming Soon MSG"
            id="FDwAek"
          />
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ my: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={4}>
          <ScoreWidget />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <TrendWidget />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Monitor;

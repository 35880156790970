import React from "react";
import { DataStore } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Box,
  Paper,
  Typography,
  Stack,
  FormGroup,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Client,
  CreditHistory,
  CreditOperationsEnum,
} from "../../../../../models";

const SEED_CREDITS_COUNT = 150;

function CreateClientStep({ proceed, onComplete, onAbort }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
        desc: "",
        sector: "",
        vat: "",
      },
      validationSchema: Yup.object({
        name: Yup.string().required(
          intl.formatMessage({
            description: "Create Client: Company name is required.",
            defaultMessage:
              "Company name is required. If you don't have company use your own name.",
            id: "EPAi/0",
          }),
        ),
        desc: Yup.string(),
        sector: Yup.number(),
        vat: Yup.string(),
      }),
      onSubmit: async ({ name, desc, sector, vat }) => {
        const _client = await DataStore.save(
          new Client({
            name,
            desc: desc || undefined,
            sector: sector || undefined,
            vat: vat || undefined,
            oStep: 2,
            credits: SEED_CREDITS_COUNT,
          }),
        );
        await DataStore.save(
          new CreditHistory({
            clientID: _client.id,
            credits: SEED_CREDITS_COUNT,
            op: CreditOperationsEnum["SEED"],
          }),
        );
        onComplete({ client: _client });
      },
    });

  React.useEffect(() => {
    if (proceed === true) {
      handleSubmit();
    }
  }, [proceed, handleSubmit]);

  React.useEffect(() => {
    const countErrors = Object.keys(errors).length;
    if (countErrors > 0) {
      onAbort();
    }
  }, [errors, onAbort]);

  return (
    <Container sx={{ py: 3, px: isMobile ? 0 : 2 }}>
      <Typography variant="h3" component="div" gutterBottom>
        <FormattedMessage
          description="Onboarding Create Company Title"
          defaultMessage="Create Company Account"
          id="CiDLYy"
        />
      </Typography>
      <Box component="form" autoComplete="off" noValidate>
        <Paper elevation={1} sx={{ p: 2 }}>
          <Stack>
            <Typography variant="h6" gutterBottom component="div">
              <FormattedMessage
                description="Onboarding Create Company Subtitle"
                defaultMessage="Tell us a little bit about your company:"
                id="Ijwn+r"
              />
            </Typography>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend">
                <FormattedMessage
                  description="Input Title: Name of your company"
                  defaultMessage="Name of your company"
                  id="s7mlLQ"
                />
              </FormLabel>
              <TextField
                required
                id="name"
                label={intl.formatMessage({
                  description: "Input Label: Company Name",
                  defaultMessage: "Company Name",
                  id: "GIRrrb",
                })}
                variant="outlined"
                margin="dense"
                placeholder={intl.formatMessage({
                  description: "Input Placeholder: Company Name",
                  defaultMessage: "Xtun Inc.",
                  id: "Eobz89",
                })}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 1 }}>
                <FormattedMessage
                  description="Input Title: Company Sector"
                  defaultMessage="What sector is your company in"
                  id="JR5ige"
                />
              </FormLabel>
              <FormControl>
                <InputLabel id="sector-label">
                  <FormattedMessage
                    description="Input Label: Company Sector"
                    defaultMessage="Company Sector"
                    id="e0sLJv"
                  />
                </InputLabel>
                <Select
                  labelId="sector-label"
                  name="sector"
                  label={intl.formatMessage({
                    description: "Input Label: Company Sector",
                    defaultMessage: "Company Sector",
                    id: "e0sLJv",
                  })}
                  variant="outlined"
                  margin="dense"
                  value={values.sector}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.sector && Boolean(errors.sector)}
                >
                  <MenuItem value={1}>Technology</MenuItem>
                  <MenuItem value={2}>Communication Services</MenuItem>
                  <MenuItem value={3}>Consumer Cyclical</MenuItem>
                  <MenuItem value={4}>Consumer Defensive</MenuItem>
                  <MenuItem value={5}>Financial</MenuItem>
                  <MenuItem value={6}>Healthcare</MenuItem>
                  <MenuItem value={7}>Real Estate</MenuItem>
                  <MenuItem value={8}>Energy</MenuItem>
                  <MenuItem value={9}>Industrials</MenuItem>
                  <MenuItem value={10}>Utilities</MenuItem>
                  <MenuItem value={11}>Basic Materials</MenuItem>
                  <MenuItem value={12}>Other</MenuItem>
                </Select>
                {touched.sector && !!errors.sector && (
                  <FormHelperText>{errors.sector}</FormHelperText>
                )}
              </FormControl>
            </FormGroup>

            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend">
                <FormattedMessage
                  description="Input Title: Company Description"
                  defaultMessage=" Short description of your company"
                  id="HmaDIA"
                />
              </FormLabel>
              <TextField
                id="desc"
                label={intl.formatMessage({
                  description: "Input Label: Company Description",
                  defaultMessage: "Company Description",
                  id: "pq+Ssw",
                })}
                variant="outlined"
                margin="dense"
                placeholder={intl.formatMessage({
                  description: "Input Placeholder: Company Description",
                  defaultMessage: "Describe your company with a few words...",
                  id: "Usr9b8",
                })}
                value={values.desc}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.desc && Boolean(errors.desc)}
                helperText={touched.desc && errors.desc}
                multiline={true}
                minRows={3}
              />
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend">
                <FormattedMessage
                  description="Input Title: Company VAT"
                  defaultMessage="VAT number of your company"
                  id="Heu0TH"
                />
              </FormLabel>
              <TextField
                id="vat"
                label={intl.formatMessage({
                  description: "Input Label: Company VAT",
                  defaultMessage: "Company VAT",
                  id: "rJtvOK",
                })}
                variant="outlined"
                margin="dense"
                placeholder="BG123456789"
                value={values.vat}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.vat && Boolean(errors.vat)}
                helperText={touched.vat && errors.vat}
              />
            </FormGroup>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
}

export default CreateClientStep;

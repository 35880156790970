import React, { useState, useEffect } from "react";
import { Hub, DataStore } from "aws-amplify";

import { Client } from "../models";

export const ClientContext = React.createContext();

function ClientContextProvider(props) {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [active, setActive] = useState(undefined);

  const fetchClients = async () => {
    const clients = await DataStore.query(Client);
    setClients(clients);
    setLoading(false);
  };

  const push = (client) => {
    setClients((_clients) => {
      const clients = [..._clients];
      clients.push(client);

      return clients;
    });
  };

  // init
  useEffect(() => {
    DataStore.start();
    const listener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchClients();
      }
    });

    return () => {
      listener();
    };
  }, []);

  return (
    <ClientContext.Provider
      value={{
        clients,
        active,
        setActive,
        push,
        loading,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
}

export default ClientContextProvider;

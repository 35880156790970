import { Typography, Stack } from "@mui/material";
import { Flex, Loader } from "@aws-amplify/ui-react";

function FullscreenLoader({ caption }) {
  return (
    <Flex alignItems="center" justifyContent="center" height="100%">
      <Stack alignItems="center" spacing={2} textAlign="center">
        <Loader />
        {caption && <Typography variant="overline">{caption}</Typography>}
      </Stack>
    </Flex>
  );
}

export default FullscreenLoader;

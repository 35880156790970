export function set(key, value, ttl) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, window.btoa(JSON.stringify(item)));
}

export function get(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(window.atob(itemStr));
  if (new Date().getTime() > item.expiry) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
}

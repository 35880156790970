import { Link } from "react-router-dom";
import { Container, Box, Typography, Stack, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import HomeIcon from "@mui/icons-material/Home";

function NotFound() {
  return (
    <Container sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h3">
            <FormattedMessage
              description="404 Page Title"
              defaultMessage="We couldn't find this page."
              id="ZcMTsJ"
            />
          </Typography>
          <Box>
            <Button
              component={Link}
              variant="contained"
              to="/"
              size="large"
              startIcon={<HomeIcon />}
            >
              <FormattedMessage
                description="404 Page CTA"
                defaultMessage="Go Back to Homepage"
                id="t1a/2J"
              />
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}

export default NotFound;

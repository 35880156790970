import React from "react";
import { DataStore } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Box,
  Paper,
  Typography,
  Stack,
  FormGroup,
  FormLabel,
  TextField,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Client } from "../../../../../models";

function AddSocialMediaStep({ client, proceed, onComplete, onAbort }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        fb: client.fb || "",
        ig: client.ig || "",
        tw: client.tw || "",
        site: client.site || "",
      },
      validationSchema: Yup.object({
        fb: Yup.string(),
        ig: Yup.string(),
        tw: Yup.string(),
        site: Yup.string().url(
          intl.formatMessage({
            description: "Error: Invalid website URL",
            defaultMessage:
              "Please, enter valid website URL. (Starting with https://)",
            id: "JNSq9f",
          }),
        ),
      }),
      onSubmit: async ({ fb, ig, tw, site }) => {
        let _client = await DataStore.query(Client, client.id);
        _client = await DataStore.save(
          Client.copyOf(_client, (updated) => {
            updated.fb = fb || undefined;
            updated.ig = ig || undefined;
            updated.tw = tw || undefined;
            updated.site = site || undefined;
            updated.oStep = _client.oStep + 1;
          }),
        );
        onComplete({ client: _client });
      },
    });

  React.useEffect(() => {
    if (proceed === true) {
      handleSubmit();
    }
  }, [proceed, handleSubmit]);

  React.useEffect(() => {
    const countErrors = Object.keys(errors).length;
    if (countErrors > 0) {
      onAbort();
    }
  }, [errors, onAbort]);

  return (
    <Container sx={{ py: 3, px: isMobile ? 0 : 2 }}>
      <Typography variant="h3" component="div" gutterBottom>
        <FormattedMessage
          description="Onboarding Add Social Media Title"
          defaultMessage="Add Social Media"
          id="cpOVHn"
        />
      </Typography>
      <Box component="form" autoComplete="off" noValidate>
        <Paper elevation={1} sx={{ p: 2 }}>
          <Stack>
            <Typography variant="h6" gutterBottom component="div">
              <FormattedMessage
                description="Onboarding Add Social Media Subtitle"
                defaultMessage="Where people can find your business online:"
                id="3DwysE"
              />
            </Typography>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                Facebook
              </FormLabel>
              <FormControl>
                <InputLabel htmlFor="fb">
                  <FormattedMessage
                    description="Input Label: Your Facebook Page"
                    defaultMessage="Your Facebook Page"
                    id="6HV73q"
                  />
                </InputLabel>
                <OutlinedInput
                  id="fb"
                  label={intl.formatMessage({
                    description: "Input Label: Your Facebook Page",
                    defaultMessage: "Your Facebook Page",
                    id: "6HV73q",
                  })}
                  value={values.fb}
                  placeholder="xtun"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.fb && Boolean(errors.fb)}
                  startAdornment={
                    <InputAdornment position="start">
                      https://facebook.com/
                    </InputAdornment>
                  }
                />
                {touched.fb && !!errors.fb && (
                  <FormHelperText>{errors.fb}</FormHelperText>
                )}
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                Instagram
              </FormLabel>
              <FormControl>
                <InputLabel htmlFor="ig">
                  <FormattedMessage
                    description="Input Label: Your Instagram Handle"
                    defaultMessage="Your Instagram Handle"
                    id="wkO2zo"
                  />
                </InputLabel>
                <OutlinedInput
                  id="ig"
                  label={intl.formatMessage({
                    description: "Input Label: Your Instagram Handle",
                    defaultMessage: "Your Instagram Handle",
                    id: "wkO2zo",
                  })}
                  value={values.ig}
                  placeholder="xtun"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ig && Boolean(errors.ig)}
                  startAdornment={
                    <InputAdornment position="start">
                      https://instagram.com/
                    </InputAdornment>
                  }
                />
                {touched.ig && !!errors.ig && (
                  <FormHelperText>{errors.ig}</FormHelperText>
                )}
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend" sx={{ mb: 2 }}>
                Twitter
              </FormLabel>
              <FormControl>
                <InputLabel htmlFor="tw">
                  <FormattedMessage
                    description="Input Label: Your Twitter Handle"
                    defaultMessage="Your Twitter Handle"
                    id="rfnC2c"
                  />
                </InputLabel>
                <OutlinedInput
                  id="tw"
                  label={intl.formatMessage({
                    description: "Input Label: Your Twitter Handle",
                    defaultMessage: "Your Twitter Handle",
                    id: "rfnC2c",
                  })}
                  value={values.tw}
                  placeholder="xtun"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.tw && Boolean(errors.tw)}
                  startAdornment={
                    <InputAdornment position="start">
                      https://twitter.com/
                    </InputAdornment>
                  }
                />
                {touched.tw && !!errors.tw && (
                  <FormHelperText>{errors.tw}</FormHelperText>
                )}
              </FormControl>
            </FormGroup>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel component="legend">
                <FormattedMessage
                  description="Input Title: Website"
                  defaultMessage="Website"
                  id="4rFsmx"
                />
              </FormLabel>
              <TextField
                id="site"
                label={intl.formatMessage({
                  description: "Input Label: Your Website",
                  defaultMessage: "Your Website",
                  id: "bl3puW",
                })}
                variant="outlined"
                margin="dense"
                placeholder="https://xtun.io"
                value={values.site}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.site && Boolean(errors.site)}
                helperText={touched.site && errors.site}
              />
            </FormGroup>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
}

export default AddSocialMediaStep;

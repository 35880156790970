import React from "react";
import { DataStore } from "aws-amplify";
import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import { NotFound } from "../../../../components";
import { Poll } from "../../../../models";
import { ShareViaMSGForm, ShareViaQR, ShareViaLink } from "../components";

function SharePoll() {
  const navigate = useNavigate();
  const { id, channel } = useParams();
  const [activeChannel, setActiveChannel] = React.useState(channel);
  const [loading, setLoading] = React.useState(true);
  const [poll, setPoll] = React.useState(null);

  const handleChannelChange = (_channel) => () => {
    setActiveChannel((currentChannel) =>
      currentChannel !== _channel ? _channel : null,
    );
  };

  React.useEffect(() => {
    DataStore.query(Poll, id).then(async (_poll) => {
      if (_poll) {
        setPoll(_poll);
      }
      setLoading(false);
    });
  }, [id]);

  React.useEffect(() => {
    navigate(`/poll/${id}/share${activeChannel ? `/${activeChannel}` : ""}`, {
      replace: true,
    });
  }, [activeChannel, navigate, id]);

  if (loading) {
    return "Loading...";
  }

  if (!poll) {
    return <NotFound />;
  }

  return (
    <>
      <Container maxWidth="md" sx={{ py: 2 }}>
        <Box>
          <Typography variant="h4" component="div">
            {poll.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <FormattedMessage
              defaultMessage="Share Poll"
              description="Share Poll Subtitle"
              id="fEiBsI"
            />
          </Typography>
        </Box>
        <Accordion
          expanded={activeChannel === "sms"}
          onChange={handleChannelChange("sms")}
          disabled={true}
        >
          <AccordionSummary
            expandIcon={<ExpandCircleDownIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="overline">
              <FormattedMessage
                defaultMessage="Share via SMS"
                description="Poll: Share via Message Section Title"
                id="KoFCDI"
              />
              {" - "}
              <FormattedMessage
                defaultMessage="Coming Soon"
                description="Coming Soon"
                id="+aycNd"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShareViaMSGForm channel="SMS" />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={activeChannel === "email"}
          onChange={handleChannelChange("email")}
          disabled={true}
        >
          <AccordionSummary
            expandIcon={<ExpandCircleDownIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="overline">
              <FormattedMessage
                defaultMessage="Share via Email"
                description="Poll: Share via Message Section Title"
                id="9GzYfM"
              />
              {" - "}
              <FormattedMessage
                defaultMessage="Coming Soon"
                description="Coming Soon"
                id="+aycNd"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShareViaMSGForm channel="EMAIL" />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={activeChannel === "qr"}
          onChange={handleChannelChange("qr")}
        >
          <AccordionSummary
            expandIcon={<ExpandCircleDownIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="overline">
              <FormattedMessage
                defaultMessage="Share via QR"
                description="Poll: Share via QR Section Title"
                id="jJqGhb"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShareViaQR id={id} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={activeChannel === "link"}
          onChange={handleChannelChange("link")}
        >
          <AccordionSummary
            expandIcon={<ExpandCircleDownIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="overline">
              <FormattedMessage
                defaultMessage="Share via Link"
                description="Poll: Share via Link Section Title"
                id="BoTOzC"
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShareViaLink id={id} />
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
}

export default SharePoll;

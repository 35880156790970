import { useIntl } from "react-intl";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import BaseActionCard from "./BaseActionCard";

function UploadLogoActionCard() {
  const intl = useIntl();

  return (
    <BaseActionCard
      title={intl.formatMessage({
        defaultMessage: "Upload Company Logo",
        description: "Upload Logo Widget Title",
        id: "gKOMTN",
      })}
      subheader={intl.formatMessage({
        defaultMessage:
          "Upload your logo to best present your company. Our stats shows that companies which do get better engagement rates.",
        description: "Upload Logo Widget Subheader",
        id: "NSgIB9",
      })}
      action={{
        to: "/company",
        title: intl.formatMessage({
          defaultMessage: "Upload Logo",
          description: "Upload Logo Action",
          id: "olKvI+",
        }),
      }}
      icon={<CloudUploadIcon fontSize="large" />}
    />
  );
}

export default UploadLogoActionCard;

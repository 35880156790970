import { useIntl } from "react-intl";
import InterestsIcon from "@mui/icons-material/Interests";

import BaseActionCard from "./BaseActionCard";

function AddSocialMediaActionCard() {
  const intl = useIntl();

  return (
    <BaseActionCard
      title={intl.formatMessage({
        defaultMessage: "Link Your Profiles",
        description: "Add Social Media Card Title",
        id: "rSxDux",
      })}
      subheader={intl.formatMessage({
        defaultMessage:
          "Where people can found you online? Link your social media profiles here.",
        description: "Add Social Media Card Subheader",
        id: "uzw2KY",
      })}
      action={{
        to: "/company",
        title: intl.formatMessage({
          defaultMessage: "Link Social Media",
          description: "Add Social Media Action",
          id: "UCQgOr",
        }),
      }}
      icon={<InterestsIcon fontSize="large" />}
    />
  );
}

export default AddSocialMediaActionCard;

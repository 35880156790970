import { useContext } from "react";
import { Container, Typography } from "@mui/material";

import { CompanyWidget } from "../../../../components";
import { ClientContext } from "../../../../context";
import { FormattedMessage } from "react-intl";

function Company() {
  const { active: activeClient } = useContext(ClientContext);

  // Change Company Details (Name, Address, Phone, Description, Sector)
  // Change company social media links
  // Change Logo
  // Company Preview (Add address, description and sector to the card)

  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Typography component="div" variant="h4">
        <FormattedMessage
          defaultMessage="Company Profile"
          description="Company Page Title"
          id="y+jlhG"
        />
      </Typography>
      <Typography component="div" variant="subtitle1" gutterBottom>
        <FormattedMessage
          defaultMessage="Preview and update your company profile"
          description="Company Page Subtitle"
          id="Fhi6m4"
        />
      </Typography>
      <CompanyWidget company={activeClient} />
    </Container>
  );
}

export default Company;

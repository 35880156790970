import React from "react";
import Moment from "react-moment";
import { DataStore } from "aws-amplify";
import { Container, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useIntl, FormattedMessage } from "react-intl";

import { CreditHistory } from "../../../../models";

function CreditHistoryEl() {
  const intl = useIntl();
  const [rows, setRows] = React.useState([]);

  const opTrans = (op) =>
    intl.formatMessage(
      {
        defaultMessage:
          "{op, select, SEED {Account Creation Bonus} GIFT {Bonus} TOP_UP {Top Up Purchase} AUTO_TOP_UP {Automatic Top Up Purchase} VOTE {Poll Voting Fee} MSG_SMS {SMS Message Fee} REVERSAL {Manual Reversal} other {Unspecified Operation}}",
        description: "Credit History Operations",
        id: "DRwTbK",
      },
      { op },
    );

  const columns = [
    {
      field: "op",
      headerName: intl.formatMessage({
        defaultMessage: "Operation",
        description: "Credit History Column: Operation",
        id: "H93Tkw",
      }),
      type: "singleSelect",
      valueOptions: [
        { value: "SEED", label: opTrans("SEED") },
        { value: "GIFT", label: opTrans("GIFT") },
        { value: "TOP_UP", label: opTrans("TOP_UP") },
        { value: "AUTO_TOP_UP", label: opTrans("AUTO_TOP_UP") },
        { value: "VOTE", label: opTrans("VOTE") },
        { value: "MSG_SMS", label: opTrans("MSG_SMS") },
        { value: "REVERSAL", label: opTrans("REVERSAL") },
      ],
      valueFormatter: (params) => {
        return opTrans(params.value);
      },
      flex: 3,
    },
    {
      field: "credits",
      headerName: intl.formatMessage({
        defaultMessage: "Credits",
        description: "Credit History Column: Credits",
        id: "QXohuQ",
      }),
      type: "number",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: intl.formatMessage({
        defaultMessage: "Date",
        description: "Credit History Column: Created At",
        id: "YR5vfR",
      }),
      type: "dateTime",
      flex: 1,
      renderCell: ({ value }) => {
        return <Moment fromNow>{value}</Moment>;
      },
    },
  ];

  React.useEffect(() => {
    DataStore.query(CreditHistory).then((_rows) => setRows(_rows));
  }, []);

  return (
    <Container
      maxWidth="xl"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        py: theme.spacing(2),
        height: `calc(100% - ${theme.spacing(8)})`,
      })}
    >
      <Typography variant="h3">
        <FormattedMessage
          defaultMessage="Your Credit History"
          description="Credit History Title"
          id="fLHu4L"
        />
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage
          defaultMessage="Full history of all your credit operations"
          description="Credit History Subtitle"
          id="1s3FD9"
        />
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          rows={rows}
          columns={columns}
        />
      </Box>
    </Container>
  );
}

export default CreditHistoryEl;

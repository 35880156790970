import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import {
  Dashboard,
  Monitor,
  HelpCenter,
  PollEditor,
  SharePoll,
  PollResults,
  Polls,
  TopUp,
  Company,
  Business,
  CreditHistory,
} from "./screens";
import { AppLayout } from "./components";
import { NotFound } from "../../../components";

function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppModule() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/monitor" element={<Monitor />} />
          <Route path="/help" element={<HelpCenter />} />
          <Route path="/create/poll" element={<PollEditor />} />
          <Route path="/polls" element={<Polls />} />
          <Route path="/poll/:id/edit" element={<PollEditor />} />
          <Route path="/poll/:id/results" element={<PollResults />} />
          <Route path="/poll/:id/share" element={<SharePoll />} />
          <Route path="/poll/:id/share/:channel" element={<SharePoll />} />
          <Route path="/company" element={<Company />} />
          <Route path="/business" element={<Business />} />
          <Route path="/credits/top-up" element={<TopUp />} />
          <Route path="/credits/history" element={<CreditHistory />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppModule;

import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { DataStore, SortDirection, Predicates } from "aws-amplify";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import MessageIcon from "@mui/icons-material/Message";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ArchiveIcon from "@mui/icons-material/Archive";
import Slide from "@mui/material/Slide";

import { Poll } from "../../../../../models";
import { ShareViaLink } from "../";

const POLLS_LIMIT = 5;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LatestPollsListingWidget() {
  const pollsRef = React.useRef({});
  const [polls, setPolls] = React.useState([]);
  const [pollMenu, setPollMenu] = React.useState(null);
  const [dialogOpen, setLinkDialogOpen] = React.useState(false);

  const handleLinkDialogOpen = (_poll) => {
    setLinkDialogOpen(_poll);
  };

  const handleLinkDialogClose = () => {
    setLinkDialogOpen(false);
  };

  const handleMenuOpen = (targetPoll) => {
    setPollMenu(targetPoll);
  };

  const handleMenuClose = () => {
    setPollMenu(null);
  };

  React.useEffect(() => {
    DataStore.query(Poll, Predicates.ALL, {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
      page: 0,
      limit: POLLS_LIMIT,
    }).then((_polls) => {
      setPolls(_polls);
    });
  }, []);

  return (
    <>
      <Card elevation={2}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <FormattedMessage
              defaultMessage="Latest Polls"
              description="Latest Polls Card Widget Title"
              id="q0MtBS"
            />
          </Typography>
          <List>
            {polls.map((_poll) => (
              <ListItem
                key={_poll.id}
                secondaryAction={
                  <IconButton
                    ref={(el) => (pollsRef.current[_poll.id] = el)}
                    onClick={() => handleMenuOpen(_poll)}
                    edge="end"
                    aria-label="Poll Actions"
                    size="large"
                  >
                    <ExpandCircleDownOutlinedIcon />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  dense
                  onClick={() => handleMenuOpen(_poll)}
                >
                  <ListItemText
                    primary={_poll.name}
                    secondary={
                      <Moment locale="bg" fromNow>
                        {_poll.createdAt}
                      </Moment>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </CardContent>
        {polls.length === POLLS_LIMIT && (
          <CardActions>
            <Button size="small" component={Link} to="/polls">
              <FormattedMessage
                defaultMessage="View All"
                description="View All Action"
                id="+G3Nuw"
              />{" "}
              &rarr;
            </Button>
          </CardActions>
        )}
      </Card>
      {pollMenu && (
        <Menu
          id="more-menu"
          anchorEl={pollsRef.current[pollMenu.id]}
          open={true}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": `more-button-${pollMenu.id}`,
          }}
          PaperProps={{ style: { width: "100%", maxWidth: 280 } }}
        >
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/poll/${pollMenu.id}/results`}
          >
            <ListItemIcon>
              <AutoGraphIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Poll Results"
                description="Latest Polls Card Poll Results action"
                id="CQClWN"
              />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/poll/${pollMenu.id}/edit`}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Edit Poll"
                description="Latest Polls Card Edit Poll action"
                id="ZuozpL"
              />
            </ListItemText>
          </MenuItem>
          <Divider component="li" />
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/poll/${pollMenu.id}/share/sms`}
            disabled={true}
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Share via SMS"
                description="Latest Polls Card Share via SMS action"
                id="LHpjF2"
              />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/poll/${pollMenu.id}/share/email`}
            disabled={true}
          >
            <ListItemIcon>
              <AlternateEmailIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Share via Email"
                description="Latest Polls Card Share via Email action"
                id="tRqeTp"
              />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to={`/poll/${pollMenu.id}/share/qr`}
          >
            <ListItemIcon>
              <QrCode2Icon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Share via QR"
                description="Latest Polls Card Share via QR action"
                id="e56Z4S"
              />
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              handleLinkDialogOpen(pollMenu);
            }}
          >
            <ListItemIcon>
              <InsertLinkIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Get Link"
                description="Latest Polls Card Get Link action"
                id="SaWKlJ"
              />
            </ListItemText>
          </MenuItem>
          <Divider component="li" />
          <MenuItem onClick={handleMenuClose}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText>
              <FormattedMessage
                defaultMessage="Archive Poll"
                description="Latest Polls Card Archive Poll action"
                id="thJMuH"
              />
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={Boolean(dialogOpen.id)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleLinkDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <FormattedMessage
            defaultMessage="Share {poll} via Link"
            description="Latest Polls Card Share via Link"
            id="CQvmCQ"
            values={{ poll: dialogOpen.name }}
          />
        </DialogTitle>
        <DialogContent>
          <ShareViaLink id={dialogOpen.id} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LatestPollsListingWidget;

import React, { useEffect, useContext } from "react";
import { DataStore } from "aws-amplify";
import { FormattedMessage } from "react-intl";
import { useAuthenticator } from "@aws-amplify/ui-react";

import {
  Container,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";

import { OnboardingWizard, ClientSelector } from "./screens";
import { ClientContext } from "../../../context";

function OnboardingWebWrapper({ children }) {
  const authenticator = useAuthenticator();

  const signOutHandler = async () => {
    await DataStore.clear();
    authenticator.signOut();
  };

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        paddingBottom: "env(safe-area-inset-bottom)",
      })}
    >
      <AppBar position="fixed">
        <Toolbar>
          <Container
            maxWidth="md"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" noWrap component="div">
              Xtun
            </Typography>
            <Button color="inherit" onClick={signOutHandler}>
              <FormattedMessage
                id="ZCZnZ5"
                description="Logout Action"
                defaultMessage="Logout"
              />
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {children}
    </Box>
  );
}

function OnboardingModule() {
  const {
    clients,
    setActive: setActiveClient,
    active: activeClient,
    push: addClient,
  } = useContext(ClientContext);

  useEffect(() => {
    if (!activeClient) {
      if (clients.length === 1 && clients[0].oStep === -1) {
        setActiveClient(clients[0]);
      }
    }
  }, [clients, activeClient, setActiveClient]);

  if (!activeClient) {
    if (clients.length > 1) {
      return (
        <OnboardingWebWrapper>
          <ClientSelector
            clients={clients}
            onSelect={({ _client }) => {
              setActiveClient(_client);
            }}
          />
        </OnboardingWebWrapper>
      );
    }

    // no clients or one client with onboarding not finished
    if (!clients.length || (clients.length === 1 && clients[0].oStep !== -1)) {
      return (
        <OnboardingWebWrapper>
          <OnboardingWizard
            client={clients?.[0]}
            onFinish={(_client) => {
              addClient(_client);
              setActiveClient(_client);
            }}
          />
        </OnboardingWebWrapper>
      );
    }
  }

  // should never go here
  return (
    <OnboardingWebWrapper>
      Something went wrong. Please, try again or contact support.
      <br /> Error code: 666
    </OnboardingWebWrapper>
  );
}

export default OnboardingModule;

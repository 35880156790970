import { Stack, Typography, Rating } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { PollWidgetEnum } from "../../../models";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

export const vote2val = { 0: 0, 1: 1, 2: 4, 3: 5 };
export const val2vote = { 0: 0, 1: 1, 2: 1, 3: 2, 4: 2, 5: 3 };
export const val2color = { 1: "#FF1033", 2: "#34D5E1", 3: "#FF559D" };

const icons = {
  1: (
    <ThumbDownOffAltIcon
      sx={(theme) => ({
        fontSize: theme.spacing(5),
        mr: 3,
      })}
    />
  ),
  2: (
    <ThumbUpOffAltIcon
      sx={(theme) => ({
        fontSize: theme.spacing(5),
        mr: 3,
      })}
    />
  ),
  3: (
    <FavoriteBorderIcon
      sx={(theme) => ({
        fontSize: theme.spacing(5),
      })}
    />
  ),
};

function SentimentIconContainer(props) {
  const { value, ...other } = props;

  return <span {...other}>{icons[value]}</span>;
}

function SentimentWidget({ question, onHover, onVote, value, hover }) {
  const rating = hover !== -1 ? hover : val2vote[value];

  return (
    <Stack spacing={2} alignItems="center">
      <Rating
        readOnly={!!value}
        value={val2vote[value]}
        IconContainerComponent={SentimentIconContainer}
        highlightSelectedOnly
        max={3}
        onChange={(_, _value) => {
          onVote(question.id, vote2val[_value]);
        }}
        onChangeActive={(_, _hover) => {
          onHover(_hover);
        }}
        sx={() => {
          return { color: val2color[hover !== -1 ? hover : val2vote[value]] };
        }}
      />
      {rating ? (
        <Typography sx={{ height: "1.2rem" }} variant="overline">
          <FormattedMessage
            description="Rating Widget Labels"
            defaultMessage="{widget, select, STARS {{rating, select, 1 {Poor} 2 {Below Average} 3 {Average} 4 {Good} 5 {Excellent} other {Other}}} SENTIMENT {{rating, select, 1 {Dissatisfied} 2 {Satisfied} 3 {Love} other {Other}}} other {Undefined}}"
            values={{
              rating,
              widget: PollWidgetEnum.SENTIMENT,
            }}
            id="e1S15b"
          />
        </Typography>
      ) : (
        <Typography sx={{ height: "1.2rem" }} variant="caption">
          <FormattedMessage
            description="Voting Widget: Please, choose rating"
            defaultMessage="Please, choose rating"
            id="8gufCH"
          />
        </Typography>
      )}
    </Stack>
  );
}

export default SentimentWidget;

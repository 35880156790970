import React from "react";
import { API } from "aws-amplify";
import { Box, Typography, Stack } from "@mui/material";
import { useInViewport } from "react-in-viewport";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

import { StarsWidget, SentimentWidget } from "./widgets";
import { triggerCounter } from "../../graphql/mutations";

const QuestionWidgets = {
  STARS: StarsWidget,
  SENTIMENT: SentimentWidget,
};

function QuestionWidget({ question, value = 0, onVote = () => {}, sx }) {
  const myRef = React.useRef();
  const [hover, setHover] = React.useState(-1);
  const [isCounterTriggered, setIsCounterTriggered] = React.useState(false);
  const Widget = QuestionWidgets[question.widget];
  const { inViewport, enterCount } = useInViewport(myRef);

  const reset = () => {
    setHover(-1);
  };

  React.useEffect(() => {
    if (!isCounterTriggered && inViewport && enterCount === 1 && question.id) {
      API.graphql({
        query: triggerCounter,
        authMode: "AWS_IAM",
        variables: {
          counters: [
            JSON.stringify({
              period: "1d",
              type: "imp",
              sub: `Question:${question.id}`,
            }),
          ],
        },
      });
      setIsCounterTriggered(true);
    }
  }, [isCounterTriggered, inViewport, enterCount, question]);

  React.useEffect(() => {
    if (value === 0) {
      reset();
    }
  }, [value]);

  return (
    <Stack spacing={4} sx={sx} ref={myRef}>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <BusinessCenterIcon />
          <Typography variant="body2">{question.Service.name}</Typography>
        </Stack>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {question.question}
        </Typography>
      </Stack>
      <Box justifyContent="center" display="flex">
        <Widget
          question={question}
          onVote={onVote}
          onHover={setHover}
          value={value}
          hover={hover}
        />
      </Box>
    </Stack>
  );
}

export default QuestionWidget;

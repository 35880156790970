import { useState } from "react";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";
import HelpIcon from "@mui/icons-material/Help";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const RatingPercentile = styled("div")(({ theme, selected }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  border: `1px solid #000000`,
  borderRadius: "2px",
  backgroundColor: selected ? theme.palette.secondary.main : "#FFFFFF",
}));

function ScoreWidget() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card elevation={1}>
      <CardHeader
        title="Xtun Score"
        subheader="Your Xtun score for the past 7 days."
        action={
          <IconButton aria-label="help" size="large">
            <HelpIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-evenly">
              <div></div>
              <RatingPercentile />
              <RatingPercentile />
              <RatingPercentile />
              <RatingPercentile />
              <RatingPercentile />
              <RatingPercentile selected />
              <RatingPercentile />
              <RatingPercentile />
              <RatingPercentile />
              <RatingPercentile />
              <div></div>
            </Stack>
            <Stack direction="row" justifyContent="space-evenly">
              <div>0</div>
              <div>10</div>
              <div>20</div>
              <div>30</div>
              <div>40</div>
              <div>50</div>
              <div>60</div>
              <div>70</div>
              <div>80</div>
              <div>90</div>
              <div>100</div>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={4}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Typography variant="h1" component="div">
                69
              </Typography>
              <Typography variant="h2" component="div">
                /
              </Typography>
              <Typography variant="h4" component="div">
                100
              </Typography>
            </Stack>
            <Typography variant="subtitle1" component="div">
              <Chip label="Better" color="secondary" variant="outlined" /> than{" "}
              <Chip label="30%" color="primary" variant="outlined" /> of all
              clients.
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Last 7 days
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
          <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
          <MenuItem onClick={handleClose}>Year to Date</MenuItem>
        </Menu>
      </CardActions>
    </Card>
  );
}

export default ScoreWidget;

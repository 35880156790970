import React from "react";
import ReactDOM from "react-dom";
import Amplify, { AuthModeStrategyType } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { IntlProvider } from "react-intl";
import { bgBG as coreBgBG } from "@mui/material/locale";
import { bgBG } from "@mui/x-data-grid";

import WebModule from "./modules/Web/WebModule";
import AuthModule from "./modules/Auth/AuthModule";
import { AmplifyAuthenticator } from "./components";
import { ClientContextProvider } from "./context";
import "moment/locale/bg";
import reportWebVitals from "./reportWebVitals";

import "@aws-amplify/ui-react/styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./index.css";

import awsExports from "./aws-exports";

Amplify.configure({
  ...awsExports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#24354F",
      },
      secondary: {
        main: "#5BB8DF",
      },
    },
  },
  coreBgBG,
  bgBG,
);

const isApp = window.location.origin === process.env.REACT_APP_APP_URL;

function loadLocaleData(locale) {
  switch (locale) {
    case "bg":
      return import("./lang/bg.json");
    default:
      return import("./lang/en.json");
  }
}

async function bootstrapApp() {
  const translations = await loadLocaleData("bg");

  ReactDOM.render(
    <React.StrictMode>
      <AmplifyProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <IntlProvider
              messages={translations}
              locale="bg"
              defaultLocale="en"
            >
              {isApp ? (
                <AmplifyAuthenticator>
                  <ClientContextProvider>
                    <AuthModule />
                  </ClientContextProvider>
                </AmplifyAuthenticator>
              ) : (
                <WebModule />
              )}
            </IntlProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AmplifyProvider>
    </React.StrictMode>,
    document.getElementById("root"),
  );
}

bootstrapApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
